import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlashMessageDirective} from "@atl/directives/flash-message-directive/flash-message.directive";


@NgModule({
  declarations: [FlashMessageDirective],
  imports: [CommonModule],
  exports: [FlashMessageDirective],
})
export class FlashMessageDirectiveModule {}
