import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ObjectsService} from "@atl/admin/objects/services";
import {finalize, map, tap} from "rxjs/operators";
import {IObject, TypesUtils} from "@atl/lacerta-ui-common";
import {
    GetChildrenFn,
    ILtaNodeData,
    ILtaTreeOptions,
    LayoutType,
    TreeTheme
} from "@atl/modules/tree/interfaces/tree.interface";
import {LtaTreeComponent} from "@atl/modules/tree/tree.component";
import {PreloaderModule} from "@atl/modules/preloader/preloader.module";
import {LtaTreeModule} from "@atl/modules/tree/lta-tree.module";
import {SelectModalModule} from "@atl/modules/modals/select-modal/select-modal.module";
import {BaseModalModule} from "@atl/modules/modals/base-modal/base-modal.module";
import {ModalLayoutModule} from "@atl/modules/modals/modal-layout/modal-layout.module";
import {CommonModule} from "@angular/common";
import {Subject} from "rxjs";

@UntilDestroy()
@Component({
    selector: 'lta-select-object-modal',
    templateUrl: 'select-object-modal.component.html',
    styleUrls: ['select-object-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ObjectsService],
    imports: [
        PreloaderModule,
        LtaTreeModule,
        SelectModalModule,
        BaseModalModule,
        ModalLayoutModule,
        CommonModule
    ],
    standalone: true
})
export class SelectObjectModalComponent {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSubmit: EventEmitter<IObject> = new EventEmitter<IObject>();

    selected: IObject
    public isLoadingSearchResult = true;
    public treeFilter: string = '';
    @Input() titleText = 'script.insertObjectTitle'
    @Input() modelIdsExceptions: number[]
    public searchError$ = new Subject<Error>()
    public searchResult = []
    @ViewChild('tree')
    private tree: LtaTreeComponent;

    constructor(
        public objectsService: ObjectsService,
        private cd: ChangeDetectorRef,
    ) {
    }

    @Input() set objects(obj: IObject[]) {
        if (obj && obj.length) {
            setTimeout(() => {
                this.tree.nodes = obj.map(obj => this.toTreeItem(obj))
                this.isLoadingSearchResult = false
            })
        }
    }

    private _theme: TreeTheme
    public get theme() {
        return this._theme
    }

    @Input() set theme(value: TreeTheme) {
        if (value) {
            this._theme = value
            this.treeOptions = {
                filterFn: this.filter,
                sortFn: ObjectsService.sort,
                getChildrenFn: this.loadTreeChildrenFn,
                theme: value === 'dark' ? 'dark-object-tree' : 'object-tree',
            }
        }
    }

    public loadTreeChildrenFn: GetChildrenFn = (item) => {
        return this.objectsService.getObjectChildren(item.item.id)
            .pipe(map(children => {
                return children.map(ch => this.toTreeItem(ch));
            }));
    }

    public treeOptions: ILtaTreeOptions = {
        filterFn: this.filter,
        sortFn: ObjectsService.sort,
        getChildrenFn: this.loadTreeChildrenFn,
        theme: this.theme === 'dark' ? 'dark-object-tree' : 'object-tree',
        selectAction: this.selectAction.bind(this)
    }

    public onSearch(string: string) {
        this.isLoadingSearchResult = true;
        if (!string) {
            this.isLoadingSearchResult = false;
            this.treeFilter = ''
            this.searchResult = []
            return
        }
        this.selected = null
        this.objectsService.searchForObject(string)
            .pipe(
                tap(null, err => {
                    if (err) this.treeFilter = ''
                }),
                finalize(() => {
                    this.isLoadingSearchResult = false;
                    this.cd.markForCheck()
                })
            )
            .subscribe({
                next: ({all, toExpand}) => {
                    this.searchResult = all
                    this.tree.expandNodes(toExpand, true)
                    setTimeout(() => {
                        this.treeFilter = string.toLowerCase()
                    })
                },
                error: err => {
                    this.searchError$.next(err)
                    this.isLoadingSearchResult = false;
                    this.cd.markForCheck()
                }
            })
    }

    private toTreeItem(obj: IObject): ILtaNodeData {
        const disabled = this.modelIdsExceptions?.includes(obj.model.id)
        return {
            item: obj,
            id: obj.id,
            hasChildren: TypesUtils.isDir(obj.type),
            icon: TypesUtils.getIconByModelId(obj.model.id),
            name: obj.name,
            description: obj.model?.name,
            selectable: !disabled,
            useLayout: [LayoutType.ObjectDescriptionLayout, LayoutType.ObjectSettingsLayout],
            disabled,
        };
    }

    private filter(item: IObject, filterString: string): boolean {
        return item.name.toLowerCase().indexOf(filterString) !== -1 || item.descr.toLowerCase().indexOf(filterString) !== -1
    }

    private selectAction(data: ILtaNodeData) {
        if (data.id === this.selected?.id) {
            this.selected = null
            this.tree.resetActive()
        } else {
            this.selected = data.id ? this.tree.getNodeById(data.id).data.item : null
            if (this.selected) {
                this.tree.setActive(data.id)
                this.tree.resetFocus()
            }
        }
    }
}
