import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LocalStorageService} from "@atl/lacerta-ui-common";

@Component({
    selector: 'lta-aside-layout',
    templateUrl: 'aside-layout.component.html',
    styleUrls: ['aside-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLayoutComponent implements OnInit, AfterViewInit {
    @Input() private aside: 'left' | 'right' = 'right';
    @Input() private minWidth = 450;
    @Input() private maxWidth = 450;
    @Input() private width = 450;
    @Input() private isOpen = false;
    @Input() set isEqualColumns(value: boolean) {
        this._isEqualColumns = value
        this.isOpen ? this.setVisible() : this.setHidden();
    }

    @ViewChild('main') private mainRef: ElementRef;
    @ViewChild('aside') private asideRef: ElementRef;
    @ViewChild('handler') private handlerRef: ElementRef;

    private _isEqualColumns:boolean = false
    private lastResizeWindow: number = 0;
    @Output() private onOpen: EventEmitter<void> = new EventEmitter<void>();
    @Output() private onHide: EventEmitter<void> = new EventEmitter<void>();
    @Output() private onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() private onResize: EventEmitter<void> = new EventEmitter<void>();
    @Output() private onStartResize: EventEmitter<void> = new EventEmitter<void>();
    @Output() private onEndResize: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private componentRef: ElementRef,
        private renderer: Renderer2,
        private storage: LocalStorageService,
        private route: ActivatedRoute
    ) {
    }

    get isEqualColumns() {
        return this._isEqualColumns
    }

    ngOnInit(): void {
        if (typeof this.route.component !== 'string') {
            const asideWidth = this.storage.getItem(this.route.component.name + 'AsideWidth');
            if (asideWidth) {
                this.width = asideWidth;
            }
        }
    }

    ngAfterViewInit() {
        this.isOpen ? this.setVisible() : this.setHidden();

        if (this.aside === 'left') {
            this.renderer.setStyle(this.componentRef.nativeElement, 'flex-direction', 'row-reverse');
            this.renderer.setStyle(this.handlerRef.nativeElement, 'right', 0);
        } else {
            this.renderer.setStyle(this.handlerRef.nativeElement, 'left', 0);
        }
    }

    resize(event) {
        const prevX = event.x;
        const main = this.mainRef.nativeElement.getBoundingClientRect();
        const fullWidth = this.componentRef.nativeElement.clientWidth;

        const mousemove = e => {
            const newX = prevX - e.x;
            const width = this.aside === 'right' ? main.width - newX : main.width + newX;
            if (width >= fullWidth - this.maxWidth) {
                this.removeTransition();
                this.disableUserSelect();
                this.renderer.setStyle(this.mainRef.nativeElement, 'width', width + 'px');
                this.renderer.setStyle(this.asideRef.nativeElement, 'width', fullWidth - width + 'px');
                this.width = fullWidth - width;
                this.onResize.emit();
            }
        };

        const mouseup = () => {
            window.removeEventListener('mousemove', mousemove);
            window.removeEventListener('mouseup', mouseup);
            this.addTransition();
            this.enableUserSelect();
            this.savePanelSize();
            this.onEndResize.emit();
        };

        this.onStartResize.emit();
        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);
    }

    hidePanel() {
        this.onHide.emit();
        this.setHidden();
        this.isOpen = false;
    }

    openPanel() {
        this.onOpen.emit();
        this.setVisible();
        this.isOpen = true;
    }

    togglePanel() {
        this.isOpen ? this.hidePanel() : this.openPanel();
        this.onToggle.emit(this.isOpen);
    }

    @HostListener('window:resize', ['$event'])
    private onWindowResize(event) {
        if (this.isOpen) {
            this.lastResizeWindow = Date.now();
            this.removeTransition();
            this.setVisible();

            setTimeout(() => {
                if (Date.now() - this.lastResizeWindow >= 500) {
                    this.addTransition();
                }
            }, 500);
        }
    }

    private setVisible() {
        const fullWidth = this.componentRef.nativeElement.clientWidth;
        if(this.mainRef && this.asideRef) {
            if(this.isEqualColumns) {
                this.renderer.setStyle(this.mainRef.nativeElement, 'width', '50%');
                this.renderer.setStyle(this.asideRef.nativeElement, 'width', '50%');
                this.renderer.setStyle(this.asideRef.nativeElement, 'max-width', '50%');
                this.renderer.setStyle(this.asideRef.nativeElement, 'min-width', '50%');
            } else {
                this.renderer.setStyle(this.mainRef.nativeElement, 'width', fullWidth - this.width + 'px');
                this.renderer.setStyle(this.asideRef.nativeElement, 'width', this.width + 'px');
                this.renderer.setStyle(this.asideRef.nativeElement, 'min-width', this.minWidth + 'px');
                this.renderer.setStyle(this.asideRef.nativeElement, 'max-width', this.maxWidth + 'px');
            }
        }

    }

    private setHidden() {
        if(this.mainRef && this.asideRef) {
            this.renderer.setStyle(this.mainRef.nativeElement, 'width', '100%');
            this.renderer.setStyle(this.asideRef.nativeElement, 'width', 0);
            this.renderer.setStyle(this.asideRef.nativeElement, 'min-width', 0);
        }
    }

    private addTransition() {
        this.renderer.removeClass(this.mainRef.nativeElement, 'on-move');
        this.renderer.removeClass(this.asideRef.nativeElement, 'on-move');
    }

    private removeTransition() {
        this.renderer.addClass(this.mainRef.nativeElement, 'on-move');
        this.renderer.addClass(this.asideRef.nativeElement, 'on-move');
    }

    private enableUserSelect() {
        this.renderer.removeStyle(this.asideRef.nativeElement, 'user-select');
    }

    private disableUserSelect() {
        this.renderer.setStyle(this.asideRef.nativeElement, 'user-select', 'none');
    }

    private savePanelSize() {
        if (typeof this.route.component !== 'string') {
            this.storage.setItem(this.route.component.name + 'AsideWidth', this.width);
        }
    }
}
