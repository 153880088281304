import {Component, ElementRef, EventEmitter, HostListener, Output, Self} from '@angular/core';

@Component({
    selector: 'lta-modal-layout',
    templateUrl: 'modal-layout.component.html',
    styleUrls: ['modal-layout.component.scss']
})
export class ModalLayoutComponent {
    @Output() layoutClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() escapePress: EventEmitter<void> = new EventEmitter<void>();
    private clickPrepared: boolean = false;

    constructor(@Self() private elRef: ElementRef<HTMLElement>) {
    }

    @HostListener('mouseup', ['$event'])
    onClick(event: PointerEvent) {
        if (this.clickPrepared && event.target === this.elRef.nativeElement) {
            this.layoutClick.emit(event);
        } else {
            this.clickPrepared = false;
        }
    }

    @HostListener('mousedown', ['$event'])
    onClickPrepared(event: PointerEvent) {
        if (event.target === this.elRef.nativeElement) {
            this.clickPrepared = true;
        }

    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapePress(event: KeyboardEvent) {
        this.escapePress.emit()
    }
}
