<ng-content></ng-content>

<context-menu [menuClass]="style" (open)="onOpenClose.emit(true)" (close)="onOpenClose.emit(false)">
    <ng-template
            *ngFor="let action of contextMenuActions"
            (execute)="action.click && action.click($event)"
            [disabled]="action.disabled ? action.disabled(item) : false"
            [divider]="action.divider"
            [visible]="action.visible ? action.visible   : true"
            contextMenuItem
    >
        <span
                [class]="action.type && action.type(item)"
                [style]="action.style && action.style(item)"
        >{{ action.html && action.html(item) }}</span>
    </ng-template>
</context-menu>
