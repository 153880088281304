import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TreeTheme } from '../../tree/interfaces/tree.interface';

@Component({
    selector: 'lta-select-modal',
    templateUrl: 'select-modal.component.html',
    styleUrls: ['select-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectModalComponent implements OnInit {

    @Input() titleText: string
    @Input() btnText: string = 'script.insert'
    @Input() isInsertDisabled: boolean = true
    @Input() searchError: Error
    @Input() searchResult: number
    @Input() theme: TreeTheme

    @Output() onSubmit = new EventEmitter()
    @Output() onCancel = new EventEmitter()
    @Output() onSearch = new EventEmitter<string>()
    @Output() onSearchResultIndexChange: EventEmitter<number> = new EventEmitter<number>();

    public isSearchActive = false

    constructor() {
    }

    ngOnInit(): void {
    }

}
