import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs'
import {map, tap} from 'rxjs/operators'
import {sortByField} from "@atl/shared/utils";
import {
    EventTypesHttpService,
    IEventClassSound,
    IEventType,
    IEventTypeTag,
    NewEventTypeTag
} from "@atl/lacerta-ui-common";

@Injectable()
export class EventTypesService {
    public eventTypeMap: Map<number, IEventType> = new Map<number, IEventType>()
    public eventSoundsMap: Map<number, IEventClassSound> = new Map<number, IEventClassSound>()
    private eventTypesSubject: BehaviorSubject<IEventType[]> = new BehaviorSubject<IEventType[]>([]);
    public eventTypes$: Observable<IEventType[]> = this.eventTypesSubject.asObservable()
        .pipe(map(eventTypes => this.sortEvents(eventTypes)))
    private activeEventTypeSubject: BehaviorSubject<IEventType> = new BehaviorSubject<IEventType>(null);
    public activeEventType$: Observable<IEventType> = this.activeEventTypeSubject.asObservable()
    private eventTypeTagsSubject: BehaviorSubject<IEventTypeTag[]> = new BehaviorSubject<IEventType[]>([]);
    public eventTypeTags$: Observable<IEventTypeTag[]> = this.eventTypeTagsSubject.asObservable()
    private eventClassSoundsSubject: BehaviorSubject<IEventClassSound[]> = new BehaviorSubject<IEventClassSound[]>([]);
    public eventClassSounds$: Observable<IEventClassSound[]> = this.eventClassSoundsSubject.asObservable()

    constructor(private eventTypesHttp: EventTypesHttpService) {
    }

    public getById(id: number): IEventType {
        return this.eventTypeMap.get(id)
    }

    public getSoundById(id: number): string {
        return this.eventSoundsMap.get(id)?.sound
    }

    public getEventTypes(): Observable<IEventType[]> {
        return this.eventTypesHttp
            .getAllEventTypes()
            .pipe(tap(eventTypes => {
                this.eventTypesSubject.next(eventTypes)
                eventTypes.forEach(value => {
                    this.eventTypeMap.set(value.id, value)
                })
            }))
    }

    public getEventTypesTags(): Observable<IEventTypeTag[]> {
        return this.eventTypesHttp
            .getAllEventTypesTags()
            .pipe(tap(eventTypeTags => {
                this.eventTypeTagsSubject.next(eventTypeTags)

            }))
    }

    public getEventClassSounds(): Observable<IEventClassSound[]> {
        return this.eventTypesHttp
            .getAllEventClassSounds()
            .pipe(tap(sounds => {
                this.eventClassSoundsSubject.next(sounds)
                sounds.forEach(value => {
                    this.eventSoundsMap.set(value.id, value)
                })

            }))
    }


    public createEventClassSound(eventClassSound: IEventClassSound): Observable<IEventClassSound> {
        return this.eventTypesHttp.createEventClassSound(eventClassSound)
            .pipe(
                tap(sound => {
                    this.eventClassSoundsSubject.next(sortByField([...this.eventClassSoundsSubject.value, sound], (item) => item.name.toLowerCase()))
                })
            )
    }

    public updateEventClassSound(eventClassSound: Partial<IEventClassSound>, id: number): Observable<IEventClassSound> {
        return this.eventTypesHttp.updateEventClassSound(eventClassSound, id)
            .pipe(
                tap(eventClassSound => {
                    this.eventClassSoundsSubject.next(this.eventClassSoundsSubject.value.map(v => v.id === eventClassSound.id ? eventClassSound : v))
                })
            )
    }

    public deleteEventClassSound(id: number) {
        return this.eventTypesHttp.deleteEventClassSound(id).pipe(tap(() => {
            this.eventClassSoundsSubject.next(this.eventClassSoundsSubject.value.filter(v => v.id !== id))
        }))
    }

    public createEventTypeTag(eventTypeTag: NewEventTypeTag): Observable<IEventTypeTag> {
        return this.eventTypesHttp.createEventTypeTag(eventTypeTag)
            .pipe(
                tap(eventTypeTag => {
                    this.eventTypeTagsSubject.next(sortByField([...this.eventTypeTagsSubject.value, eventTypeTag], (item) => item.name.toLowerCase()))
                })
            )
    }

    public updateEventTypeTag(eventTypeTag: Partial<IEventTypeTag>): Observable<IEventTypeTag> {
        return this.eventTypesHttp.updateEventTypeTag(eventTypeTag, eventTypeTag.id)
            .pipe(
                tap(eventTypeTag => {
                    this.eventTypeTagsSubject.next(this.eventTypeTagsSubject.value.map(v => v.id === eventTypeTag.id ? eventTypeTag : v))
                })
            )
    }

    public deleteEventTypeTag(eventTypeTag: IEventTypeTag) {
        return this.eventTypesHttp.deleteEventTypeTag(eventTypeTag.id).pipe(tap(() => {
            this.eventTypeTagsSubject.next(this.eventTypeTagsSubject.value.filter(v => v.id !== eventTypeTag.id))
        }))
    }

    public setActiveEventType(eventType: IEventType) {
        this.activeEventTypeSubject.next(eventType)
    }

    public addBlankEventType(): void {
        this.activeEventTypeSubject.next({
            id: null,
            can_check: null,
            lacerta_system: null,
            color_background: '#ffffff',
            color_background_checked: '#ffffff',
            color_text: '#ffffff',
            color_text_checked: '#ffffff',
            name: null,
            priority: null,
            event_class_tags: []
        })
    }

    public createEventType(eventType: IEventType): Observable<IEventType> {
        return this.eventTypesHttp.createEventType(eventType)
            .pipe(
                tap(eventType => {
                    this.activeEventTypeSubject.next(eventType)
                    this.eventTypesSubject.next([...this.eventTypesSubject.value, eventType])
                    this.eventTypeMap.set(eventType.id, eventType)
                })
            )
    }

    public updateEventType(eventType: Partial<IEventType>): Observable<IEventType> {
        return this.eventTypesHttp.updateEventType(eventType, eventType.id)
            .pipe(
                tap(eventType => {
                    if (eventType.event_class_sound?.id) eventType.event_class_sound = this.eventSoundsMap.get(eventType.event_class_sound.id)
                    this.activeEventTypeSubject.next(eventType)
                    this.eventTypesSubject.next(this.eventTypesSubject.value.map(v => v.id === eventType.id ? eventType : v))
                    this.eventTypeMap.set(eventType.id, eventType)
                })
            )
    }

    public deleteEventType(eventType: IEventType): Observable<number> {
        return this.eventTypesHttp.deleteEventType(eventType.id).pipe(tap((id) => {
            this.activeEventTypeSubject.next(null)
            this.eventTypesSubject.next(this.eventTypesSubject.value.filter(v => v.id !== id))
            this.eventTypeMap.delete(eventType.id)
        }))
    }

    private sortEvents(eventTypes: IEventType[]): IEventType[] {
        return eventTypes.sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }
}
