import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform<T>(values: T[], filterString: string, fields: string[] = ['name'], caseSensitive: boolean = false): T[] {
        if (!filterString) return values
        const caseAdjust = (v: string) => {
            if (caseSensitive) return v
            return v.toLowerCase()
        }
        return values.filter(v => {
            let passed = false
            fields.every(field => {
                if (caseAdjust(v[field]).indexOf(caseAdjust(filterString)) !== -1) {
                    passed = true
                }
                return !passed;
            })
            return passed
        });
    }
}
