<lta-modal-layout
        (escapePress)="onCancel.emit()"
        (layoutClick)="onCancel.emit()"
>
    <lta-modal class="modal">
        <lta-svg-icon
                class="icon"
                icon="trash"
                size="50"
        ></lta-svg-icon>
        <div class="body">
            <ng-content></ng-content>
        </div>
        <p class="hint">{{'lta-modal.You can’t undo this action' | translate}}</p>
        <ng-content select=".footer"></ng-content>
        <div class="actions">
            <lta-btn
                    (onClick)="onCancel.emit()"
                    size="md"
                    theme="secondary"
            >{{ 'lta.cancel' | translate }}</lta-btn>
            <lta-btn
                    (onClick)="onDeleteClicked()"
                    [disabled]="isLoading"
                    [isLoading]="isLoading"
                    size="md"
                    theme="danger"
            >{{ deleteBtnText | translate }}</lta-btn>
        </div>
    </lta-modal>
</lta-modal-layout>
