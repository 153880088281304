import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {
    EventSetsHttpService,
    EventSetSorting,
    IEventSet,
    IEventSetFilters,
    IEventSetResponse
} from "@atl/lacerta-ui-common";
import {map, tap} from "rxjs/operators";
import {FormBuilder, FormControl} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class EventSetsService {
    eventSetsMap: Map<number, IEventSet> = new Map<number, IEventSet>()
    private eventSetsSubject: BehaviorSubject<IEventSet[]> = new BehaviorSubject<IEventSet[]>([]);
    eventSets$: Observable<IEventSet[]> = this.eventSetsSubject.asObservable()
        .pipe(map(values => this.sortSets(values)))
    private activeEventSetSubject: BehaviorSubject<IEventSet> = new BehaviorSubject<IEventSet>(null);
    activeEventSet$: Observable<IEventSet> = this.activeEventSetSubject.asObservable();
    private lastRequestFilter: IEventSetFilters = null
    private filterForm = this.fb.group({
        filter_str: this.fb.control<string>(''),
        filter_models: this.fb.control<number[]>(null),
        pagination: this.fb.group({
            count: this.fb.control<number>(0),
            from: this.fb.control<number>(0),
        }),
        sorting: this.fb.control<EventSetSorting[]>(null),
    })
    public filterChange$ = this.filterForm.valueChanges
    private totalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    public total$: Observable<number> = this.totalSubject.asObservable()

    constructor(
        private eventSetsHttp: EventSetsHttpService,
        private fb: FormBuilder
    ) {
    }


    public get currentPage() {
        return this.fromFormControl.value / this.limit + 1
    }

    public get countFormControl() {
        return this.filterForm.get('pagination.count') as FormControl
    }

    public get isResponsiveCount(): boolean {
        return !this.countFormControl.value
    }

    private _responsiveCount: number = 0

    public get responsiveCount(): number {
        return this._responsiveCount
    }

    public set responsiveCount(value: number) {
        if (this._responsiveCount === value) return;
        this._responsiveCount = value
    }

    public get limit() {
        return this.isResponsiveCount ? this.responsiveCount : this.countFormControl.value
    }

    public get fromFormControl() {
        return this.filterForm.get('pagination.from') as FormControl
    }

    public get sortingFormControl() {
        return this.filterForm.get('sorting') as FormControl
    }

    public get filterModelFormControl() {
        return this.filterForm.get('filter_models') as FormControl
    }

    public setFilterString(str: string) {
        if (this.filterForm.get('filter_str').value === str) return
        this.filterForm.get('filter_str').patchValue(str)
    }

    public changePage(page: number) {
        this.fromFormControl.patchValue((page * this.limit) - this.limit)
    }

    getEventSets(useFormFilter = false, filter?: IEventSetFilters): Observable<IEventSetResponse> {
        if (useFormFilter) {
            this.resetPageIfNeeded()
            this.lastRequestFilter = this.getFiltersFormValue()
        }
        return this.eventSetsHttp.getEventSets(useFormFilter ? this.lastRequestFilter : filter || {})
            .pipe(tap(res => {
                this.eventSetsSubject.next(res.event_settings_sets);
                this.totalSubject.next(res.total)
                res.event_settings_sets.forEach(val => this.eventSetsMap.set(val.id, val))
            }))
    }

    public getFiltersFormValue(): IEventSetFilters {
        return {
            count: this.limit,
            from: this.fromFormControl.value,
            filter_str: this.filterForm.get('filter_str').value,
            filter_models: this.filterForm.get('filter_models').value,
            sorting: this.sortingFormControl.value
        }
    }

    getEventSetById(id: number): IEventSet {
        return this.eventSetsMap.get(id);
    }

    setActiveEventSet(eventSet: IEventSet) {
        this.activeEventSetSubject.next(eventSet);
    }

    addBlankEventSet(): void {
        this.activeEventSetSubject.next({
            id: null,
            name: null,
            description: null,
            model_id: null,
            event_settings: [],
        })
    }

    createEventSet(eventSet: IEventSet): Observable<IEventSet> {
        return this.eventSetsHttp.createEventSet(eventSet)
            .pipe(
                tap(value => {
                    this.activeEventSetSubject.next(value)
                    this.eventSetsSubject.next([...this.eventSetsSubject.value, value])
                    this.eventSetsMap.set(value.id, value)
                })
            )
    }

    updateEventSet(eventSet: IEventSet): Observable<IEventSet> {
        return this.eventSetsHttp.updateEventSet(eventSet)
            .pipe(
                tap(value => {
                    this.activeEventSetSubject.next(value);
                    this.eventSetsSubject.next(this.eventSetsSubject.value.map(v => v.id === value.id ? value : v));
                    this.eventSetsMap.set(value.id, value);
                })
            )
    }

    deleteEventSet(eventSet: IEventSet): Observable<void> {
        return this.eventSetsHttp.deleteEventSet(eventSet.id).pipe(tap(() => {
            this.activeEventSetSubject.next(null);
            this.eventSetsSubject.next(this.eventSetsSubject.value.filter(v => v.id !== eventSet.id));
            this.eventSetsMap.delete(eventSet.id);
        }))
    }

    private resetPageIfNeeded() {
        if (!this.lastRequestFilter) return
        const countHasChanged = this.countFormControl.value === 0 ? this.responsiveCount !== this.lastRequestFilter.count : this.countFormControl.value !== this.lastRequestFilter.count

        if (countHasChanged) {
            this.fromFormControl.patchValue(0, {emitEvent: false})
        }
    }

    private sortSets(eventSets: IEventSet[]): IEventSet[] {
        return eventSets.sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }
}
