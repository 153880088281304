<lta-info-popup
        [popupStyle]="{width:'auto',padding:'0'}"
        [position]="'top'"
        [showIcon]="false"
        class="info-popup"
>
    <div
            [ngClass]="{error: mapping.error}"
            class="mapping-item title"
    >

        <lta-svg-icon
                *ngIf="!mapping.write_flag"
                [icon]="'driver_read'"
                class="item__icon"
                size="20"
        ></lta-svg-icon>
        <lta-svg-icon
                *ngIf="mapping.write_flag"
                [icon]="'driver_write'"
                class="item__icon"
                size="20"
        ></lta-svg-icon>
        <div class="mapping-item__wrapper">
            <span
                    [ngClass]="{'mapping-item__error': mapping.error}"
                    class="mapping-item__name text-ellipsis"
            >
                {{ mapping.obj_name_shrt }} {{ mapping.error ? '(' + mapping.error.error_msg + ')' : '' }}
            </span>
        </div>
        <lta-svg-icon
                *ngIf="mapping.error"
                class="item__icon"
                icon="danger"
                size="20"
        ></lta-svg-icon>
    </div>

    <lta-object-hierarchy
            (onNameClick)="urlService.goToObject($event)"
            [expandAll]="true"
            [objectPath]="path$ | async"
            class="modal"
    ></lta-object-hierarchy>
</lta-info-popup>
