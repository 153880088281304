import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// tslint:disable-next-line:interface-name
export interface FlashMessageOptionsType {
	classes?: string[];
	isHideCloseButton?: boolean;
	timeout?: number;
}

// tslint:disable-next-line:interface-name
export interface FlashMessageType {
	content: IFlashMessage;
	options: FlashMessageOptionsType;
	timestamp?: number;
}

const flashMessageOptionsDefault: FlashMessageOptionsType = {
	classes: ['alert-info'],
	timeout: 7000
};

export interface IFlashMessage {
	title?: string;
	body?: FlashMessageBody;
	type?: 'info' | 'success' | 'warning' | 'danger';
}

export interface FlashMessageBody {
    body?: string;
    title?: string;
}

@Injectable({providedIn: 'root'})
export class FlashMessagesService {
	private messages: BehaviorSubject<FlashMessageType[]> = new BehaviorSubject<FlashMessageType[]>([]);
	public messages$: Observable<FlashMessageType[]> = this.messages.asObservable();

	show(content: IFlashMessage, options: FlashMessageOptionsType = flashMessageOptionsDefault): FlashMessageType {
		const timestamp = +new Date();
		const newMessage = {
			content,
			timestamp,
			options: {
				...flashMessageOptionsDefault,
				...options,
			},
		};

		this.messages.next([
			...this.messages.getValue(),
			newMessage
		]);

		this.closeOldMessages(newMessage);

		return newMessage;
	}

	closeOldMessages(message: FlashMessageType): void {
		setTimeout(() => {
			this.messages.next([
				...this.messages.getValue().filter(msg => {
					const timeout = msg.options.timeout ? msg.options.timeout : 0;
					const timestamp = msg.timestamp ? msg.timestamp : 0;
					return (timestamp + timeout) > +new Date();
				})
			]);
		}, message.options.timeout);
	}

	close(message: FlashMessageType): void {
		this.messages.next(this.messages.getValue().filter(el => el !== message));
	}

	showSuccess(content: IFlashMessage): void {
		content.type = 'success';
		this.show(content, {classes: [`alert-success`]});
	}

	showInfo(content: IFlashMessage): void {
		content.type = 'info';
		this.show(content, flashMessageOptionsDefault);
	}

	showContent(content: any, options: FlashMessageOptionsType = flashMessageOptionsDefault): FlashMessageType {
		return this.show(content, options);
	}

	showWarning(content: IFlashMessage): void {
		content.type = 'warning';
		this.show(content, {classes: [`alert-warning`]});
	}

	showDanger(content: IFlashMessage): void {
		content.type = 'danger';
		this.show(content, {classes: [`alert-danger`]});
	}

	showError(error: any): void {
		console.error(error);
		const content: IFlashMessage = {body: error.error ?? error.message ?? error};
		content.type = 'danger';
		this.show(content, {classes: [`alert`, `alert-danger`], timeout: 70000});
	}

	removeMessageByError(error: string) {
		let filteredMessages = this.messages.getValue().filter(message => message.content.body !== error)
		this.messages.next(filteredMessages)
	}

	clearMessages() {
		this.messages.next([])
	}
}

@Injectable()
export class FlashMessageCurrent implements FlashMessageType {
	content = {};
	options = {};
	timestamp = 1;
}
