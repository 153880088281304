import {Component, OnInit} from '@angular/core';
import {UrlService} from '@atl/shared/services/url.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'lta-toolbar-history-navigation',
    templateUrl: 'toolbar-history-navigation.component.html',
    styleUrls: ['toolbar-history-navigation.component.scss']
})
export class ToolbarHistoryNavigationComponent implements OnInit {
    canGoBack$: Observable<boolean>;
    canGoForward$: Observable<boolean>;

    constructor(private urlService: UrlService) {
    }

    ngOnInit() {
        this.canGoBack$ = this.urlService.canGoBack$;
        this.canGoForward$ = this.urlService.canGoForward$;
    }

    forward() {
        this.urlService.goForward();
    }

    back() {
        this.urlService.goBack();
    }
}
