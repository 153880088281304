import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'lta-modal',
    templateUrl: 'base-modal.component.html',
    styleUrls: ['base-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseModalComponent implements OnInit {
    @Input() withCloseBtn: boolean = false

    @Output() onClose = new EventEmitter()

    constructor() {
    }

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        event.stopPropagation()
    }

    ngOnInit(): void {
    }

}
