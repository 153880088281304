<div class="main" #main>
	<ng-content select="[main]"></ng-content>
</div>
<div class="aside" #aside>
	<div class="handler" #handler></div>
	<ng-content select="[aside]"></ng-content>
</div>



