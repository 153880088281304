<div
        class="item"
>
    <lta-svg-icon
            [icon]="getIconByModelId(object.model.id)"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
    <span class="item__name">{{ object.name }}</span><span class="item__model">: {{ object.model.name }}</span>
</div>
{{ active }}
