import {Pipe, PipeTransform} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {nanoToMilliseconds} from "@atl/lacerta-ui-common";

@UntilDestroy()
@Pipe({
    name: 'nanosecondsToMilliseconds',
})
export class NanosecondsToMillisecondsPipe implements PipeTransform {
    constructor() {
    }

    transform(value: number): number {
        return nanoToMilliseconds(value);
    }
}
