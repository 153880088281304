import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {NavigationCancel, NavigationCancellationCode, NavigationEnd, NavigationSkipped, Router} from '@angular/router';
import {filter, tap} from 'rxjs/operators';
import {MenuService} from './menu.service';
import {Observable} from 'rxjs';
import {IUser} from '@app/@atl/administration/users/interfaces';
import {RestrictedPages} from "@atl/authorization/services/permissions-accessor";
import {USER_SERVICE} from "@atl/lacerta-ui-common";
import {UserServiceImpl} from "@atl/authorization/services";

@Component({
    selector: 'lta-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    providers: [
        MenuService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
    public user$: Observable<IUser> = this.userService.user$.pipe(tap(() => this.cd.markForCheck()))
    public userPicture$: Observable<ArrayBuffer | string> = this.userService.userPicture$
    protected readonly RestrictedPages = RestrictedPages;

    constructor(
        public menuService: MenuService,
        private router: Router,
        @Inject(USER_SERVICE) public userService: UserServiceImpl,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(event => (event instanceof NavigationEnd || event instanceof NavigationSkipped)))
            .subscribe(() => {
                if (this.menuService.isOpen) {
                    this.menuService.closeMainMenu();
                }
                this.cd.markForCheck()
            });
    }

    isOpen(target: string): boolean {
        return this.menuService.isOpened(target);
    }

    isLinkActive(link: string) {
        if (this.router.url.includes('?')) {
            let linkWithoutQuery = this.router.url.split('?')[0]
            return linkWithoutQuery.includes(link)
        }
        return  this.router.url.includes(link)
    }
}
