import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from "@angular/router";

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
      if (curr.routeConfig?.path === 'composite' && future.routeConfig?.path === 'composite/:id') {
        return true
    }
    return future.routeConfig === curr.routeConfig
  }
}
