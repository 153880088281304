import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FocusService} from "@atl/shared/services/focus.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export const FOCUS_SEARCH = 'search'

@UntilDestroy()
@Component({
    selector: 'lta-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, AfterViewInit {
    @Input() disabled = false;
    @Input() value: string;
    @Input() placeholder: string;
    @Input() theme: string = '';
    @Input() clear = false;
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSearchResultIndexChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() onClear: EventEmitter<any> = new EventEmitter();
    @ViewChild('inputTarget') public inputTarget: ElementRef
    public currentSearchResultIndex: number

    constructor(private focusService: FocusService) {
    }

    private _searchResult: number;

    public get searchResult() {
        return this._searchResult;
    }

    @Input() set searchResult(r: number) {
        if (r > 0) {
            this._searchResult = r;
            this.currentSearchResultIndex = 1
            this.onSearchResultIndexChange.emit(this.currentSearchResultIndex)
        } else {
            this._searchResult = null;
        }
    }

    ngAfterViewInit() {
        this.focusService.listenToFocus(FOCUS_SEARCH).pipe(untilDestroyed(this)).subscribe(() => {
            this.inputTarget.nativeElement.focus();
        })
    }

    public isFocused(el: Element) {
        return document.activeElement === el
    }

    ngOnInit(): void {
    }

    _onChange() {
        this.onChange.emit(this.value);
    }

    _onSearch() {
        this.onSearch.emit(this.value);
    }

    _onClear() {
        this.value = '';
        this.onClear.emit();
    }

    onNext() {
        this.currentSearchResultIndex++
        if (this.currentSearchResultIndex > this.searchResult) {
            this.currentSearchResultIndex = 1
        }
        this.onSearchResultIndexChange.emit(this.currentSearchResultIndex)
    }

    onBack() {
        this.currentSearchResultIndex--
        if (this.currentSearchResultIndex < 1) {
            this.currentSearchResultIndex = this.searchResult
        }
        this.onSearchResultIndexChange.emit(this.currentSearchResultIndex)
    }
}
