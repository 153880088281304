<lta-modal-layout
        (escapePress)="onCancel.emit()"
        (layoutClick)="onCancel.emit()"
>
    <lta-modal
            [style.height]="height"
            [style.width]="width"
            class="modal"
    >
        <div class="header">
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="body">
            <ng-content select="[body]"></ng-content>
        </div>
        <div class="footer">
            <ng-content select="[footer]"></ng-content>
        </div>
    </lta-modal>
</lta-modal-layout>
