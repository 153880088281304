<div class="page">
    <div class="page__info">
        <h2 class="title">404 - {{ 'lta-404.Page not found' | translate }}</h2>
        <lta-btn size="lg" theme="secondary" class="go-back" (onClick)="backToThePlatform()">
            <span>{{ 'lta-404.Go back to the platform' | translate }}
                <lta-svg-icon icon="arrow-right" size="20"></lta-svg-icon></span>
        </lta-btn>
    </div>
</div>
<svg id="svg" viewBox="0 0 1440 350" xmlns="http://www.w3.org/2000/svg"
     class="transition duration-300 ease-in-out delay-150">
    <style>
        .path-0 {
            animation: pathAnim-0 15s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes pathAnim-0 {
            0% {
                d: path("M 0,400 C 0,400 0,133 0,133 C 91.37799043062202,149.42105263157896 182.75598086124404,165.8421052631579 285,164 C 387.24401913875596,162.1578947368421 500.3540669856459,142.05263157894734 582,128 C 663.6459330143541,113.94736842105264 713.8277511961724,105.94736842105264 810,109 C 906.1722488038276,112.05263157894736 1048.334928229665,126.15789473684211 1161,132 C 1273.665071770335,137.8421052631579 1356.8325358851675,135.42105263157896 1440,133 C 1440,133 1440,400 1440,400 Z");
            }
            25% {
                d: path("M 0,400 C 0,400 0,133 0,133 C 106.133971291866,147.25837320574163 212.267942583732,161.51674641148324 301,154 C 389.732057416268,146.48325358851676 461.0622009569378,117.19138755980862 562,122 C 662.9377990430622,126.80861244019138 793.4832535885167,165.7177033492823 881,176 C 968.5167464114833,186.2822966507177 1013.0047846889952,167.9377990430622 1099,156 C 1184.9952153110048,144.0622009569378 1312.4976076555024,138.5311004784689 1440,133 C 1440,133 1440,400 1440,400 Z");
            }
            50% {
                d: path("M 0,400 C 0,400 0,133 0,133 C 73.76076555023923,111.18181818181819 147.52153110047846,89.36363636363636 248,104 C 348.47846889952154,118.63636363636364 475.67464114832546,169.72727272727272 586,166 C 696.3253588516745,162.27272727272728 789.7799043062201,103.72727272727272 882,91 C 974.2200956937799,78.27272727272728 1065.2057416267942,111.36363636363637 1158,126 C 1250.7942583732058,140.63636363636363 1345.3971291866028,136.8181818181818 1440,133 C 1440,133 1440,400 1440,400 Z");
            }
            75% {
                d: path("M 0,400 C 0,400 0,133 0,133 C 115.69377990430621,129.8421052631579 231.38755980861242,126.68421052631578 309,132 C 386.6124401913876,137.31578947368422 426.1435406698565,151.10526315789474 528,141 C 629.8564593301435,130.89473684210526 794.0382775119617,96.89473684210526 902,98 C 1009.9617224880383,99.10526315789474 1061.7033492822966,135.31578947368422 1142,147 C 1222.2966507177034,158.68421052631578 1331.1483253588517,145.8421052631579 1440,133 C 1440,133 1440,400 1440,400 Z");
            }
            100% {
                d: path("M 0,400 C 0,400 0,133 0,133 C 91.37799043062202,149.42105263157896 182.75598086124404,165.8421052631579 285,164 C 387.24401913875596,162.1578947368421 500.3540669856459,142.05263157894734 582,128 C 663.6459330143541,113.94736842105264 713.8277511961724,105.94736842105264 810,109 C 906.1722488038276,112.05263157894736 1048.334928229665,126.15789473684211 1161,132 C 1273.665071770335,137.8421052631579 1356.8325358851675,135.42105263157896 1440,133 C 1440,133 1440,400 1440,400 Z");
            }
        }
    </style>
    <defs>
        <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#3b7cff1a"></stop>
            <stop offset="95%" stop-color="#3b7cff0d"></stop>
        </linearGradient>
    </defs>
    <path
            d="M 0,400 C 0,400 0,133 0,133 C 91.37799043062202,149.42105263157896 182.75598086124404,165.8421052631579 285,164 C 387.24401913875596,162.1578947368421 500.3540669856459,142.05263157894734 582,128 C 663.6459330143541,113.94736842105264 713.8277511961724,105.94736842105264 810,109 C 906.1722488038276,112.05263157894736 1048.334928229665,126.15789473684211 1161,132 C 1273.665071770335,137.8421052631579 1356.8325358851675,135.42105263157896 1440,133 C 1440,133 1440,400 1440,400 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
    <style>
        .path-1 {
            animation: pathAnim-1 20s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes pathAnim-1 {
            0% {
                d: path("M 0,400 C 0,400 0,266 0,266 C 107.92344497607655,258.29665071770336 215.8468899521531,250.59330143540672 297,257 C 378.1531100478469,263.4066985645933 432.53588516746413,283.9234449760765 532,282 C 631.4641148325359,280.0765550239235 776.0095693779904,255.71291866028713 887,257 C 997.9904306220096,258.28708133971287 1075.4258373205741,285.2248803827751 1162,291 C 1248.5741626794259,296.7751196172249 1344.287081339713,281.3875598086124 1440,266 C 1440,266 1440,400 1440,400 Z");
            }
            25% {
                d: path("M 0,400 C 0,400 0,266 0,266 C 83.48325358851676,276.51674641148327 166.96650717703352,287.0334928229665 263,286 C 359.0334928229665,284.9665071770335 467.61722488038265,272.38277511961724 576,268 C 684.3827751196173,263.61722488038276 792.5645933014355,267.4354066985646 898,270 C 1003.4354066985645,272.5645933014354 1106.1244019138758,273.8755980861244 1196,273 C 1285.8755980861242,272.1244019138756 1362.9377990430621,269.0622009569378 1440,266 C 1440,266 1440,400 1440,400 Z");
            }
            50% {
                d: path("M 0,400 C 0,400 0,266 0,266 C 74.58373205741626,286.488038277512 149.1674641148325,306.97607655502395 242,293 C 334.8325358851675,279.02392344497605 445.9138755980862,230.58373205741623 548,223 C 650.0861244019138,215.41626794258377 743.1770334928228,248.68899521531102 831,257 C 918.8229665071772,265.311004784689 1001.377990430622,248.6602870813397 1102,246 C 1202.622009569378,243.3397129186603 1321.311004784689,254.66985645933016 1440,266 C 1440,266 1440,400 1440,400 Z");
            }
            75% {
                d: path("M 0,400 C 0,400 0,266 0,266 C 114.83253588516749,266.88995215311 229.66507177033498,267.7799043062201 316,282 C 402.334928229665,296.2200956937799 460.17224880382764,323.7703349282296 541,306 C 621.8277511961724,288.2296650717704 725.6459330143541,225.13875598086125 825,225 C 924.3540669856459,224.86124401913875 1019.2440191387561,287.67464114832535 1121,305 C 1222.7559808612439,322.32535885167465 1331.377990430622,294.1626794258373 1440,266 C 1440,266 1440,400 1440,400 Z");
            }
            100% {
                d: path("M 0,400 C 0,400 0,266 0,266 C 107.92344497607655,258.29665071770336 215.8468899521531,250.59330143540672 297,257 C 378.1531100478469,263.4066985645933 432.53588516746413,283.9234449760765 532,282 C 631.4641148325359,280.0765550239235 776.0095693779904,255.71291866028713 887,257 C 997.9904306220096,258.28708133971287 1075.4258373205741,285.2248803827751 1162,291 C 1248.5741626794259,296.7751196172249 1344.287081339713,281.3875598086124 1440,266 C 1440,266 1440,400 1440,400 Z");
            }
        }</style>
    <defs>
        <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="5%" stop-color="#3b7cff1a"></stop>
            <stop offset="95%" stop-color="#3b7cff0d"></stop>
        </linearGradient>
    </defs>
    <path
            d="M 0,400 C 0,400 0,266 0,266 C 107.92344497607655,258.29665071770336 215.8468899521531,250.59330143540672 297,257 C 378.1531100478469,263.4066985645933 432.53588516746413,283.9234449760765 532,282 C 631.4641148325359,280.0765550239235 776.0095693779904,255.71291866028713 887,257 C 997.9904306220096,258.28708133971287 1075.4258373205741,285.2248803827751 1162,291 C 1248.5741626794259,296.7751196172249 1344.287081339713,281.3875598086124 1440,266 C 1440,266 1440,400 1440,400 Z"
            stroke="none" stroke-width="0" fill="url(#gradient)"
            class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
</svg>
