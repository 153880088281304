import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@Injectable({
    providedIn: 'root'
})
export class AppSettings {
    public displayWidth = 1920;
    public displayHeight = 1080;
    public zoomFactor = 1;
    public scaleByWidht = false;
    public topPanelHeight = 60;
    public panelHeight = 148;
    public headerHeight = 50;
    public svgPadding = 10;
    public scaledPanel = false;
    public svgHeight: number = this.displayHeight - this.panelHeight - this.headerHeight;
 }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
