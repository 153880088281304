import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalLayoutComponent } from './modal-layout.component';



@NgModule({
  declarations: [ModalLayoutComponent],
  exports: [ModalLayoutComponent],
  imports: [
    CommonModule
  ]
})
export class ModalLayoutModule { }
