import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter, first, map, tap} from "rxjs/operators";
import {skipNull} from "@atl/shared/utils";
import {
    FigmaStatus,
    FigmaStatusRes,
    IFigmaFileMeta,
    ISettings,
    ITrendsSettings,
    SaveBtnState,
    SavingStatus,
    SettingsHttpService
} from "@atl/lacerta-ui-common";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public readonly trendsSettingsField = 'trends'
    private readonly mainSettingsPrefix = 'main_'
    private readonly figmaSettingsPrefix = 'figma_'

    private settingsSubject = new BehaviorSubject<ISettings[]>(null)
    public mainSettings$ = this.settingsSubject.asObservable().pipe(skipNull(), map(settings => settings.filter(item => item.name.includes(this.mainSettingsPrefix))))
    public figmaSettings$ = this.settingsSubject.asObservable().pipe(skipNull(), map(settings => settings.filter(item => item.name.includes(this.figmaSettingsPrefix))))
    public trendsSettings$ = this.settingsSubject.asObservable()
        .pipe(
            skipNull(),
            map(settings => settings.find(item => item.name.includes(this.trendsSettingsField))),
            map(setting => setting?.value ? JSON.parse(setting?.value) as ITrendsSettings : undefined)
        )

    private figmaFilesSubject = new BehaviorSubject<IFigmaFileMeta[]>(null)
    public figmaFiles$ = this.figmaFilesSubject.asObservable()

    private figmaStatusSubject = new BehaviorSubject<FigmaStatus>(null)
    public figmaStatus$ = this.figmaStatusSubject.asObservable()

    private saveBtnSubject = new Subject()
    public saveBtn$ = this.saveBtnSubject.asObservable()

    private isSaveBtnDisabledSubject = new BehaviorSubject<boolean>(true)
    public isSaveBtnDisabled$ = this.isSaveBtnDisabledSubject.asObservable()

    private unsavedSettingsSubject = new Subject<ISettings[]>()
    public unsavedSettings$ = this.unsavedSettingsSubject.asObservable()

    private saveBtnStateSubject = new BehaviorSubject<SaveBtnState>('SAVE')
    public saveBtnState$ = this.saveBtnStateSubject.asObservable()

    private savingStatusSubject = new BehaviorSubject<SavingStatus>(null)
    public savingStatus$ = this.savingStatusSubject.asObservable().pipe(
        filter(v => v !== "SAVING"),
        map(v => v !== 'ERROR'),
        first(),
    )

    constructor(
        private settingsHttp: SettingsHttpService
    ) {
    }


    public setSavingStatus(status: SavingStatus) {
        this.savingStatusSubject.next(status)
    }

    public setUnsavedSettings(settings: ISettings[]) {
        this.unsavedSettingsSubject.next(settings)
    }

    public setSaveBtnState(state: SaveBtnState) {
        this.saveBtnStateSubject.next(state)
    }

    public setSaveBtnStatus(isDisabled: boolean) {
        this.isSaveBtnDisabledSubject.next(isDisabled)
    }

    public pressSave() {
        this.saveBtnSubject.next(null)
    }

    public getFigmaComponents(): Observable<IFigmaFileMeta[]> {
        return this.settingsHttp.getFigmaFiles(true).pipe(first(), tap(v => {
            this.figmaFilesSubject.next(v)
        }))
    }

    public getSettings(): Observable<ISettings[]> {
        return this.settingsHttp.getSettings()
            .pipe(
                tap((settings) => {
                    this.settingsSubject.next(settings)
                })
            )
    }

    public saveSettings(settings: ISettings[]): Observable<ISettings[]> {
        return this.settingsHttp.addSettings(settings).pipe(first(), tap(settings => {
            this.settingsSubject.next(settings)
        }))
    }

    public syncFigma(): Observable<null> {
        return this.settingsHttp.syncFigma()
    }

    public getFigmaStatus(): Observable<FigmaStatusRes> {
        return this.settingsHttp.getFigmaStatus()
            .pipe(
                tap(value => {
                    this.figmaStatusSubject.next(value.status)
                })
            )
    }
}
