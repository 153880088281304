<lta-search-panel
        (onSearch)="onSearch.emit($event)"
        (onSearchResultIndexChange)="onSearchResultIndexChange.emit($event)"
        (onSearchActive)="isSearchActive = true"
        [ngClass]="theme"
        [class.search-active]="isSearchActive"
        [isSelectModal]="true"
        [searchError]="searchError"
        [searchResult]="searchResult"
        [titleText]="titleText"
        [theme]="theme"
        [isFullWidth]="true"
></lta-search-panel>
<div class="body">
    <ng-content></ng-content>
</div>
<div class="actions" [ngClass]="theme">
    <lta-btn
            (onClick)="onCancel.emit();$event.stopImmediatePropagation()"
            size="md"
            [theme]="theme === 'dark' ? 'dark cancel' : 'secondary'"
    >{{ 'lta.cancel' | translate }}
    </lta-btn>
    <lta-btn
            (onClick)="onSubmit.emit();$event.stopImmediatePropagation()"
            [disabled]="isInsertDisabled"
            size="md"
            [theme]="theme ? 'dark apply' : 'primary'"
    >{{ btnText | translate }}
    </lta-btn>
</div>
