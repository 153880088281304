import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {BehaviorSubject, from} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SvgService {
    sprite: string | undefined;
    svgFile: string | undefined;
    svgContainer: HTMLDivElement;
    spritesReady$ = new BehaviorSubject(false)

    constructor(private http: HttpClient) {
        this.http.get('/assets/sprites/sprite.svg', {responseType: 'blob'})
            .pipe(first())
            .subscribe(sprite => {
                this.sprite = window.URL.createObjectURL(sprite);

                from(sprite.text()).pipe(first()).subscribe(text => {
                    this.svgFile = text;
                    this.spritesReady$.next(true)
                })

            });
    }

    public getRawSvg(id: string) {
        if (!this.svgContainer) {
            this.svgContainer = document.createElement('div')
            this.svgContainer.innerHTML = this.svgFile;
        }

        const svg = this.svgContainer.querySelector('#' + id)
        return svg.outerHTML.replace(/symbol/g, 'svg')
    }
}
