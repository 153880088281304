import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {map, switchMap, tap} from "rxjs/operators";
import {isEqual} from "lodash";
import {ModelsHttpService} from "@atl/admin/models/services/models-http.service";
import {
    IObject,
    ICreateModel,
    ICreateModelChild,
    IModel,
    IModelChild,
    IModelComponent,
    IModelTree,
    ISetModelComponent,
    isModel,
    isModelChild,
    IType,
    IUpdateModel,
    IUpdateModelChild,
    Types
} from "@atl/lacerta-ui-common";
import {IScriptParam} from "@atl/modules/script-editor/interfaces/script.interfaces";
import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";

@UntilDestroy()
@Injectable()
export class ModelsService {
    public activeModelParent: number
    public createdModel$ = new Subject<IModel>();
    public deletedModelId$ = new Subject<number>();
    public updatedModel$ = new Subject<IModel>();
    private activeModelSubject: BehaviorSubject<IModel | IModelChild> = new BehaviorSubject<IModel | IModelChild>(null);
    public activeModel$: Observable<IModel | IModelChild> = this.activeModelSubject.asObservable()
    private activeModelsComponentsSubject: BehaviorSubject<IModelComponent[]> = new BehaviorSubject<IModelComponent[]>([]);
    public activeModelsComponents$: Observable<IModelComponent[]> = this.activeModelsComponentsSubject.asObservable()
    private modelsChildrenSubject: BehaviorSubject<Map<number, IModelChild[]>> = new BehaviorSubject<Map<number, IModelChild[]>>(new Map<number, IModelChild[]>());
    public modelsChildren$: Observable<Map<number, IModelChild[]>> = this.modelsChildrenSubject.asObservable()
    private modelsSubject: BehaviorSubject<IModel[]> = new BehaviorSubject<IModel[]>([]);
    public models$: Observable<IModel[]> = this.modelsSubject.asObservable()
        .pipe(
            map(models => models.sort((a, b) => ModelsService.sort(a, b)))
        );
    private typesSubject: BehaviorSubject<IType[]> = new BehaviorSubject<IType[]>([]);
    public types$: Observable<IType[]> = this.typesSubject.asObservable()

    constructor(private modelsHttp: ModelsHttpService) {
    }

    static sort(a: IModel | IModelChild, b: IModel | IModelChild): number {
        const sortString = (a: string, b: string) => a === b ? 0 : a > b ? 1 : -1
        if (isModel(a) && isModel(b)) {
            if (a.id === a.type_id && b.id === b.type_id) {
                if (a.type_id === b.type_id) {
                    return sortString(a.name,b.name);
                } else {
                    return sortString(a.name,b.name);
                }
            } else if (a.id === a.type_id) {
                return -1;
            } else if (b.id === b.type_id) {
                return 1;
            }
            return sortString(a.name,b.name);
        } else if (isModelChild(a) && isModelChild(b)) {
            if (a.type_id === a.model_id && b.type_id === b.model_id) {
                if (a.type_id === b.type_id) {
                    return sortString(a.child_name,b.child_name);
                } else {
                    return sortString(a.model_name,b.model_name);
                }
            } else if (a.type_id === a.model_id && b.type_id !== b.model_id) {
                return -1
            } else if (a.type_id !== a.model_id && b.type_id === b.model_id) {
                return 1
            } else {
                return sortString(a.child_name,b.child_name);
            }
        }
        return 0;
    }

    static filter(item: IModel | IModelChild, filterString: string): boolean {
        if (isModelChild(item)) {
            return item.child_name.toLowerCase().indexOf(filterString) !== -1 || item.child_descr.toLowerCase().indexOf(filterString) !== -1
        }
        return item.name.toLowerCase().indexOf(filterString) !== -1 || item.descr.toLowerCase().indexOf(filterString) !== -1
    }

    static modelTreeToScriptParam(model: IModelTree, omitValue: boolean = false): IScriptParam {
        return {
            name: model.name,
            type: model.type_id,
            var: model.path,
            children: model.children?.filter(ch => omitValue ? ch.name !== 'value' : true).map(c => ModelsService.modelTreeToScriptParam(c, omitValue))
        }
    }

    public isActiveModel(model: IModel | IModelChild): boolean {
        return isEqual(model, this.activeModelSubject.getValue())
    }

    public setActiveModel(model: IModel | IModelChild, parentId: number = null) {
        this.activeModelParent = parentId
        this.activeModelSubject.next(model)
    }

    public getAllModels(): Observable<IModel[]> {
        return this.modelsHttp.getAllModels().pipe(tap(models => this.modelsSubject.next(models)))
    }

    public getAllTypes(): Observable<{ types: IType[] }> {
        return this.modelsHttp.getAllTypes().pipe(tap(res => this.typesSubject.next(res.types)))
    }

    public getModelById(id: number): Observable<IModel> {
        return this.modelsHttp.getModelById(id)
    }

    public getModelTreeById(id: number, withParams: boolean): Observable<IModelTree> {
        return this.modelsHttp.getModelTreeById(id, withParams)
    }

    public addBlank(parent: IModel | IModelChild) {
        if (parent && !isModelChild(parent)) {
            const child: IModelChild = {
                id: null,
                child_name: '',
                model_name: parent.name,
                child_descr: '',
                type_id: parent.type_id,
                model_id: null,
                parent_id: parent.id
            }
            this.setActiveModel(child, parent.id)
        } else {
            const model: IModel = {
                id: null,
                name: '',
                descr: '',
                type_id: Types.Dir,
                children: [],
                tm_create: null,
                tm_update: null,
                components: null
            }
            this.setActiveModel(model)
        }
    }

    public createModel(model: ICreateModel): Observable<IModel> {
        return this.modelsHttp.createModel(model).pipe(tap(model => {
            this.createdModel$.next(model)
            const models = this.modelsSubject.value
            models.push(model)
            this.activeModelSubject.next(model)
            this.modelsSubject.next(models)
        }))
    }

    public updateModel(id: number, model: IUpdateModel): Observable<IModel> {
        return this.modelsHttp.updateModel(id, model).pipe(tap(model => {
            this.updatedModel$.next(model)
            const models = this.modelsSubject.value.map(m => {
                if (m.id === model.id) {
                    return model
                }
                return m
            })
            this.activeModelSubject.next(model)
            this.modelsSubject.next(models)
        }))
    }

    public delete({parent, child, del_aff_obj}: { parent: number, child?: number, del_aff_obj?: boolean }) {
        if (child) {
            return this.modelsHttp.deleteChildFromModelById(child).pipe(tap(() => {
                this.activeModelSubject.next(null)
                const models = this.modelsSubject.value
                this.modelsSubject.next(models.map(m => {
                    if (m.id === parent) {
                        m.children = m.children?.filter(c => c.id !== child)
                        return m
                    } else {
                        return m
                    }
                }))

            }))
        } else {
            return this.modelsHttp.deleteModel(parent, del_aff_obj).pipe(tap(() => {
                this.deletedModelId$.next(parent)
                this.activeModelSubject.next(null)
                const models = this.modelsSubject.value.filter(m => m.id !== parent)
                this.modelsSubject.next(models)

                const childrenMap = this.modelsChildrenSubject.value
                childrenMap.forEach((children, key) => {
                    children.forEach(ch => {
                        if (ch.model_id === parent) {
                            childrenMap.set(key, children.filter(ch => ch.id !== parent))
                            this.modelsChildrenSubject.next(childrenMap)
                        }
                    })
                })
            }))
        }

    }

    public getModelChildrenById(id: number): Observable<IModelChild[]> {
        return this.modelsHttp.getModelChildrenById(id)
            .pipe(
                tap(x => {
                    const map = this.modelsChildrenSubject.value
                    map.set(id, x)
                    this.modelsChildrenSubject.next(map)
                }),
                map(value => value.map(ch => {
                    return {...ch, parent_id: id}
                }))
            )
    }

    public getModelComponents(modelId: number): Observable<IModelComponent[]> {
        return this.modelsHttp.getModelComponents(modelId).pipe(tap((components) => {
            this.activeModelsComponentsSubject.next(components)
        }))
    }

    public addChildToModel(body: ICreateModelChild): Observable<IModelChild> {
        return this.modelsHttp.addChildToModel(this.activeModelParent, body)
            .pipe(
                map(model => {
                    let child: IModelChild
                    const models = this.modelsSubject.value.map(m => {
                        if (m.id === model.id) {
                            child = model.children.find(c => c.child_name === body.child_name)
                            return model
                        }
                        return m
                    })
                    const map = this.modelsChildrenSubject.value
                    const modelsChildren = map.get(model.id) || []
                    map.set(model.id, [...modelsChildren, child])

                    this.modelsChildrenSubject.next(map)
                    this.activeModelSubject.next(child)
                    this.modelsSubject.next(models)
                    return child
                }))

    }

    public updateModelChild(modelId: number, child: IUpdateModelChild): Observable<IModelChild> {
        return this.modelsHttp.updateModelChild(modelId, child)
            .pipe(
                tap(value => {
                    const map = this.modelsChildrenSubject.value
                    const activeModelsChildren = map.get(this.activeModelParent).map(ch => {
                        if (ch.id === modelId) {
                            return value
                        }
                        return ch
                    })
                    map.set(this.activeModelParent, activeModelsChildren)
                })
            )
    }

    public getObjectsByModelId(id: number): Observable<IObject[]> {
        return this.modelsHttp.getObjectsByModelId(id)
    }

    public setModelComponent(modelId: number, componentId: number, body: ISetModelComponent): Observable<IModelComponent> {
        return this.modelsHttp.setModelComponent(modelId, componentId, body).pipe(tap(cmp => {
            let components = this.activeModelsComponentsSubject.value
            let included = false
            components = components.map(c => {
                if (c.component.id === cmp.component.id) {
                    included = true
                    return cmp
                } else {
                    return c
                }
            })
            if (!included) {
                components.push(cmp)
            }
            this.activeModelsComponentsSubject.next(components)
        }))
    }

    public deleteComponentFromModel(modelId: number, componentId: number): Observable<string> {
        return this.modelsHttp.deleteComponentFromModel(modelId, componentId).pipe(tap(() => {
            let components = this.activeModelsComponentsSubject.value
            if (components) {
                components = components.filter(cmp => cmp.component.id !== componentId);
                this.activeModelsComponentsSubject.next(components)
            }
        }))
    }

    public searchForModel(string: string): Observable<IDType[]> {
        return this.modelsHttp.searchForModel(string)
            .pipe(
                switchMap((models) => {
                    if (models.length === 0) return of([])
                    return of(models.map(m => m.id))
                })
            ) as any
    }
}
