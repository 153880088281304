import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeTheme} from '../tree/interfaces/tree.interface';
import {FocusService} from "@atl/shared/services/focus.service";
import {FOCUS_SEARCH} from "@atl/modules/form-controls/search/search.component";

@Component({
    selector: 'lta-search-panel',
    templateUrl: 'search-panel.component.html',
    styleUrls: ['search-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPanelComponent {
    @Input() searchValue: string;
    @Input() titleText: string;
    @Input() isSelectModal: boolean = false;
    @Input() searchError: Error
    @Input() searchResult: number
    @Input() theme: TreeTheme
    @Input() isFullWidth: boolean

    @Output() onClear: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSearchResultIndexChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() onSearchActive = new EventEmitter<boolean>()

    public isSearchActive: boolean = false

    constructor(private focusService: FocusService) {
    }

    public onActivateSearch() {
        this.isSearchActive = !this.isSearchActive
        this.onSearchActive.emit(true)
        setTimeout(() => {
            this.focusService.focusOnId(FOCUS_SEARCH)
        })
    }
}
