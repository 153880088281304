import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from './search.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LtaSvgIconModule} from "@atl/lacerta-ui-common";


@NgModule({
    declarations: [SearchComponent],
    exports: [SearchComponent],
    imports: [
        CommonModule,
        LtaSvgIconModule,
        FormsModule,
        TranslateModule
    ]
})
export class SearchModule {
}
