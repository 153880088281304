import {Inject, Injectable} from "@angular/core"
import {Observable, of} from "rxjs"
import {switchMap} from "rxjs/operators"
import {UrlService} from "@atl/shared/services/url.service";
import {RETURN_URL_QUERY_PARAM} from "@atl/authorization/guards";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";
import {
    AuthTokens, RefreshmentStatus,
    AuthService,
    REFRESHMENT_STATUS,
    LocalStorageService,
    AuthHttpService,
    UserService, USER_SERVICE, IUser
} from '@atl/lacerta-ui-common';

@Injectable({
    providedIn: 'root'
})
export class AuthServiceImpl extends AuthService {
    constructor(
        @Inject(USER_SERVICE) private userService: UserService,
        private urlService: UrlService,
        private flashMessagesService: FlashMessagesService,
        authHttp: AuthHttpService,
        storage: LocalStorageService,
    ) {
        super(authHttp, storage);
    }

    public logout(saveReturnUrl = false): Observable<null> {
        this.flashMessagesService.clearMessages()
        return super.logout(saveReturnUrl);
    }

    public localLogout(saveReturnUrl = false) {
        this.userService.setUser(null)
        this.removeTokens()
        this.urlService.goToLogin({queryParams: {[RETURN_URL_QUERY_PARAM]: saveReturnUrl ? this.urlService.getCurrentUrl() : null}})
    }

    protected loginUser(tokens: AuthTokens): Observable<IUser> {
        this.storeTokens(tokens)
        this.storage.setItem(REFRESHMENT_STATUS, RefreshmentStatus.False)
        this.flashMessagesService.removeMessageByError('No such user or wrong password')
        return this.userService.requestCurrentUser()
            .pipe(
                switchMap((user) => {
                    const returnUrl = this.urlService.getParamsSnapshot()[RETURN_URL_QUERY_PARAM]
                    if (returnUrl) {
                        this.urlService.navigateByUrl(returnUrl)
                        return of(user)
                    }
                    this.urlService.goToHmi(user.video_screen_id ? {id: user.video_screen_id} : undefined)
                    return of(user)
                })
            )
    }
}
