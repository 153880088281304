import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {UntilDestroy} from "@ngneat/until-destroy";
import {BaseHttp, nanoToMilliseconds} from "@atl/lacerta-ui-common";

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class TimeService extends BaseHttp {
    public serverTimeDelta: number
    public serverTimezone: string;

    constructor(private http: HttpClient) {
        super();
    }

    public initTime() {
        const url = `${this.apiRoot}/time/current`;
        return this.http.get<{ time: number, zone: { offset: number, name: string } }>(url)
            .pipe(
                tap(value => {
                    this.serverTimeDelta = moment(nanoToMilliseconds(value.time)).diff(new Date());
                    this.serverTimezone = value.zone.name;
                })
            )
    }

    public getMomentWithTimezone(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean) {
        if (!this.serverTimezone) return moment(inp, format, strict)
        return moment(inp, format, strict).tz(this.serverTimezone)
    }
}
