import {IPermission} from "@atl/admin/roles/interfaces";

export enum AccessPermissionsEnum {
    "userEdit" = 1,
    "userGet" = 2,
    "roleEdit" = 3,
    "roleGet" = 4,
    "settingEdit" = 5,
    "settingGet" = 6,
    "figmaGet" = 7,
    "valueEdit" = 8,
    "valueGet" = 9,
    "websocketGet" = 10,
    "objectEdit" = 11,
    "objectGet" = 12,
    "modelEdit" = 13,
    "modelGet" = 14,
    "componentEdit" = 15,
    "componentGet" = 16,
    "videoScreenEdit" = 17,
    "videoScreenGet" = 18,
    "imitatorEdit" = 19,
    "eventClassTagsEdit" = 20,
    "eventClassTagsGet" = 21,
    "eventClassEdit" = 22,
    "eventClassGet" = 23,
    "eventEdit" = 24,
    "eventGet" = 25,
    "clusterGet" = 26,
    "driverEdit" = 27,
    "driverGet" = 28,
    "archiveGet" = 29,
    "calcGet" = 30,
    "daemonEdit" = 31,
    "daemonGet" = 32,
    "importExportEdit" = 33,
    "importExportGet" = 34,
    "selectionEdit" = 35,
    "selectionGet" = 36,
    "telegramEdit" = 37,
    "findFaceEdit" = 38,
    "findFaceGet" = 39,
    "percoEdit" = 40,
    "percoGet" = 41,
    "trassirEdit" = 42,
    "trassirGet" = 43,
    "userTokenEdit" = 44,
    "userTokenGet" = 45,
    "zabbixEdit" = 46,
    "integTrassirGet" = 47,
    "integTrassirEdit" = 48,
    "passwordForceEdit" = 49,
    "integZabbixGet" = 50,
    "integZabbixEdit" = 51,
    "integFindFaceGet" = 52,
    "integFindFaceEdit" = 53,
    "integPercoGet" = 54,
    "integPercoEdit" = 55,
    "integOrionGet" = 56,
    "integOrionEdit" = 57,
    "orionEdit" = 58,
    "userDeactivate" = 59,
    "keycloakGet" = 60,
    "keycloakEdit" = 61,
    "ldapGet" = 62,
    "ldapEdit" = 63,
    "integAsteriskGet" = 64,
    "integAsteriskEdit" = 65,
    "asteriskEdit" = 66,
    "asteriskGet" = 67,
    "objectCommentGet" = 68,
    "objectCommentEdit" = 69,
    "integToirGet" = 70,
    "integToirEdit" = 71,
    "toirEdit" = 72,
    "toirGet" = 73
}

export enum RestrictedPages {
    'roles',
    'users',
    'models',
    'objects',
    'components',
    'hmi',
    'events',
    'cluster',
    'services',
    'driverTypes',
    'drivers',
}

export interface WithPermissionsAccessor {
    hasPermission(key: AccessPermissionsEnum): boolean;

    setPermissions(permissions: IPermission[]): void;

    hasPermissionToPage(page: RestrictedPages): boolean;
}

export class PermissionsAccessor {
    private permissionsMap = new Map<number, IPermission>()

    public hasPermission(key: AccessPermissionsEnum): boolean {
        return this.permissionsMap.has(key)
    }

    public setPermissions(permissions: IPermission[]) {
        this.permissionsMap.clear()
        if (!permissions) {
            return
        }
        permissions.forEach(value => {
            this.permissionsMap.set(value.id, value)
        })
    }

    public hasPermissionToPage(page: RestrictedPages): boolean {
        switch (page) {
            case RestrictedPages.roles: {
                return this.hasPermission(AccessPermissionsEnum.roleGet)
            }
            case RestrictedPages.users: {
                return this.hasPermission(AccessPermissionsEnum.userGet) && this.hasPermission(AccessPermissionsEnum.roleGet)
            }
            case RestrictedPages.models: {
                return this.hasPermission(AccessPermissionsEnum.modelGet)
            }
            case RestrictedPages.objects: {
                return this.hasPermission(AccessPermissionsEnum.objectGet) &&
                    this.hasPermission(AccessPermissionsEnum.eventGet) &&
                    this.hasPermission(AccessPermissionsEnum.modelGet) &&
                    this.hasPermission(AccessPermissionsEnum.driverGet)
            }
            case RestrictedPages.components: {
                return this.hasPermission(AccessPermissionsEnum.componentGet)
            }
            case RestrictedPages.hmi: {
                return this.hasPermission(AccessPermissionsEnum.videoScreenGet)
            }
            case RestrictedPages.events: {
                return this.hasPermission(AccessPermissionsEnum.eventGet) &&
                    this.hasPermission(AccessPermissionsEnum.eventClassGet) &&
                    this.hasPermission(AccessPermissionsEnum.eventClassTagsGet)
            }
            case RestrictedPages.cluster: {
                return this.hasPermission(AccessPermissionsEnum.clusterGet)
            }
            case RestrictedPages.services: {
                return this.hasPermission(AccessPermissionsEnum.clusterGet)
            }
            case RestrictedPages.driverTypes: {
                return this.hasPermission(AccessPermissionsEnum.driverGet)
            }
            case RestrictedPages.drivers: {
                return this.hasPermission(AccessPermissionsEnum.driverGet) &&
                    this.hasPermission(AccessPermissionsEnum.clusterGet)
            }
        }
    }
}
