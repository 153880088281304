import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, skip, switchMap, tap} from 'rxjs/operators'
import {
    EventParams,
    EventsDataType,
    IEventsFilters,
    EventsHttpService,
    EventWebsocketMessage,
    IEvent,
    IEventType,
    milliToNanoseconds,
    PartialEventsFilters,
    RecordType,
    SortingIds
} from "@atl/lacerta-ui-common";
import {isEqual} from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EventTypesService} from "@atl/admin/alerts/services";

@UntilDestroy()
@Injectable()
export class EventsService {
    public isLoading$ = new BehaviorSubject<boolean>(false)
    private eventsSubject: BehaviorSubject<(IEvent & { event_class?: IEventType })[]> = new BehaviorSubject<(IEvent & {
        event_class?: IEventType
    })[]>([]);
    private eventsFiltersSubject: BehaviorSubject<IEventsFilters> = new BehaviorSubject<IEventsFilters>({
        limit: 50,
        dataType: 'raised',
    });
    public events$ = this.eventsSubject.asObservable().pipe(map(value => value.slice(0, this.eventsFiltersSubject.value.limit)));
    public eventsFilters$ = this.eventsFiltersSubject.asObservable().pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
    );
    private raisedEventsFilters: IEventsFilters = {
        date_end: null,
        date_start: null,
        limit: 50,
        dataType: 'raised',
        min_priority: 1,
        max_priority: 10,
        class_tags: [0],
        classes: [0],
        check: null,
        activity: null,
        sorting: null,
        object_ids: null
    }
    private historyEventsFilters: IEventsFilters = {
        check: null,
        activity: null,
        date_end: null,
        date_start: null,
        limit: 50,
        dataType: 'history',
        min_priority: 1,
        max_priority: 10,
        class_tags: [0],
        classes: [0],
        sorting: null,
        object_ids: null
    }
    private currentPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public currentPage$ = this.currentPageSubject.asObservable().pipe(skip(1));
    private totalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public total$ = this.totalSubject.asObservable();
    private eventsDataTypeSubject: BehaviorSubject<EventsDataType> = new BehaviorSubject('raised');
    public eventsDataType$ = this.eventsDataTypeSubject.asObservable();
    private getEventsTrigger$ = new Subject<[EventParams, EventsDataType]>()
    private isEventRequestOver: boolean = true
    private lastRequestEventsParams: EventParams
    private currentEventsParams: EventParams
    public isSubscribed = false

    constructor(
        private eventsHttp: EventsHttpService,
        private eventTypesService: EventTypesService
    ) {
        this.getEventsTrigger$
            .pipe(
                tap(([eventsParams]) => {
                    this.currentEventsParams = eventsParams
                    this.isLoading$.next(true)
                }),
                switchMap(([eventsParams, dataType]) => this.eventsHttp.getEvents(eventsParams, dataType)),
                untilDestroyed(this)
            )
            .subscribe({
                next: (eventResponse) => {
                    const eventsWithClass = (eventResponse.events ? eventResponse.events : []).map(v => {
                        return {...v, event_class: this.eventTypesService.getById(v.event_class_id)}
                    })
                    this.eventsSubject.next(eventsWithClass);
                    this.totalSubject.next(eventResponse.total)
                    setTimeout(() => {
                        this.isEventRequestOver = true
                        if (!isEqual(this.lastRequestEventsParams, this.currentEventsParams)) {
                            this.getEvents(true)
                        }
                    }, 1000);

                    this.isLoading$.next(false)
                },
                error: (error) => {
                    this.isEventRequestOver = true
                    if (error.status === 408 || !isEqual(this.lastRequestEventsParams, this.currentEventsParams)) {
                        this.getEvents(true)
                    }
                    this.isLoading$.next(false)
                }
            })

    }

    static isEventChecked(event: IEvent): boolean {
        return !!event.tm_check
    }

    static isEventActive(event: IEvent): boolean {
        return !event.tm_deactivate || event.tm_deactivate === 0
    }

    static canAcknowledgeEvent(event: IEvent, eventType: IEventType): boolean {
        return eventType?.can_check && !this.isEventChecked(event)
    }

    public addEventsFilters(filters: PartialEventsFilters) {
        let dataType = this.eventsDataTypeSubject.value
        this.eventsFiltersSubject.next({
            ...(dataType === 'raised' ? this.raisedEventsFilters : this.historyEventsFilters),
            ...filters
        })
        if (dataType === 'raised') {
            this.raisedEventsFilters = {...this.eventsFiltersSubject.value};
        } else {
            this.historyEventsFilters = {...this.eventsFiltersSubject.value};
        }
        if (filters.hasOwnProperty('limit')) {
            this.raisedEventsFilters.limit = filters.limit
            this.historyEventsFilters.limit = filters.limit
        }
        return this.eventsFilters$
    }

    public changeDataType(type: EventsDataType) {
        this.eventsDataTypeSubject.next(type)
        this.eventsFiltersSubject.next({
            ...(type === 'raised' ? this.raisedEventsFilters : this.historyEventsFilters)
        })

    }

    public setCurrentPage(page: number) {
        this.currentPageSubject.next(page)
    }

    getEvents(withObjectPath = false) {
        const eventsFilters = this.eventsFiltersSubject.value
        const dataType = eventsFilters.dataType
        let date = {}

        if (eventsFilters.date_start && eventsFilters.date_end) {
            let date_start = milliToNanoseconds(eventsFilters.date_start)
            let date_end = milliToNanoseconds(eventsFilters.date_end)

            if (date_start === date_end) {
                const endDayOffset = ((23 * 3600) + (59 * 60) + 59) * 1000000000
                date_end = date_start + endDayOffset
            }
            date = {date_start, date_end}

        } else {
            date = {
                ...(eventsFilters.date_start && {date_start: milliToNanoseconds(eventsFilters.date_start)}),
                ...(eventsFilters.date_end && {date_end: milliToNanoseconds(eventsFilters.date_end)}),
            }
        }

        const eventsParams: EventParams = {
            ...date,
            count: eventsFilters.limit,
            from: (this.currentPageSubject.value - 1) * eventsFilters.limit,
        }

        if (eventsFilters.min_priority) {
            eventsParams.min_priority = eventsFilters.min_priority
        }

        if (eventsFilters.max_priority) {
            eventsParams.max_priority = eventsFilters.max_priority
        }

        if (eventsFilters.class_tags && eventsFilters.class_tags.length && !eventsFilters.class_tags.includes(0)) {
            eventsParams.class_tags = eventsFilters.class_tags
        }

        if (eventsFilters.classes && eventsFilters.classes.length && !eventsFilters.classes.includes(0)) {
            eventsParams.classes = eventsFilters.classes
        }

        if (eventsFilters.sorting && eventsFilters.sorting.length) {
            eventsParams.sorting = eventsFilters.sorting
        }

        if (typeof eventsFilters.check === 'boolean') {
            eventsParams.check = eventsFilters.check
        }

        if (typeof eventsFilters.activity === 'boolean') {
            eventsParams.activity = eventsFilters.activity
        }
        if (eventsFilters.object_ids && eventsFilters.object_ids.length) {
            eventsParams.object_ids = eventsFilters.object_ids
        }
        if (withObjectPath) {
            eventsParams.object_path = true
        }
        this.lastRequestEventsParams = {...eventsParams}
        if (this.isEventRequestOver) {
            this.isEventRequestOver = false
            this.getEventsTrigger$.next([eventsParams, dataType])
        }
        return of(null)
    }

    checkEvent(objectId: number, eventId: number, userId: number, edge: boolean) {
        return this.eventsHttp.checkEvent(objectId, eventId, userId, edge)
    }

    public handleEventSignal(message: EventWebsocketMessage) {
        if (this.isSubscribed) {
            let events = this.eventsSubject.value
            // if (message.ev_msg && message.ev_msg.length) {
            //     this.getEvents(true)
            // }
            message.ev_msg.forEach(event => {
                switch (event.record_type_id) {
                    case RecordType.NEW_EVENT_MESSAGE_TYPE1: {
                        if (this.currentPageSubject.value === 1) {
                            let isUniqueEvent = true
                            const message = event
                            events.forEach(d => {
                                if(d.object_id === message.object_id && d.id_event === message.id_event && d.edge === message.edge) {
                                    isUniqueEvent = false
                                }
                            })
                     
                            if(isUniqueEvent) {
                                const eventWithClass = {
                                    ...event,
                                    event_class: this.eventTypesService.getById(event.event_class_id)
                                }
                                events = [eventWithClass, ...events]
                            }
                        }
                        break;
                    }
                    case RecordType.CHECK_EVENT_MESSAGE_TYPE2: {
                        const message = event
                        let hasEvent = false
                        events = events.map(d => {
                            if (d.object_id === message.object_id && d.id_event === message.id_event && d.edge === message.edge) {
                                let x = d as IEvent & {isChecking: boolean}
                                x.user_check_id = message.user_check_id
                                x.tm_check = message.tm_check
                                x.raised = message.raised
                                x.isChecking = false
                                hasEvent = true
                                return x
                            } else {
                                return d
                            }
                        })
                        if (!hasEvent) {
                            const eventWithClass = {
                                ...event,
                                event_class: this.eventTypesService.getById(event.event_class_id)
                            }
                            events = [eventWithClass, ...events]
                        }
                        break;
                    }
                    case RecordType.DEACTIVATE_EVENT_MESSAGE_TYPE3: {
                        const message = event
                        let hasEvent = false
                        events = events.map(d => {
                            if (d.object_id === message.object_id && d.id_event === message.id_event && d.edge === message.edge) {
                                d.tm_deactivate = message.tm_deactivate
                                d.raised = message.raised
                                hasEvent = true
                                if (this.eventsFiltersSubject.value.activity === true) {
                                    d.raised = false
                                }
                                return d
                            
                            } else {
                                return d
                            }
                        })
                        if (!hasEvent) {
                            const eventWithClass = {
                                ...event,
                                event_class: this.eventTypesService.getById(event.event_class_id)
                            }
                            events = [eventWithClass, ...events]
                        }
                        break;
                    }
                    case RecordType.UNCHECK_EVENT_MESSAGE_TYPE4:
                        const message = event
                        events = events.map(d => {
                            if (d.object_id === message.object_id && d.id_event === message.id_event && d.edge === message.edge) {
                                d.tm_check = message.tm_check
                                d.raised = message.raised
                                return d
                            } else {
                                return d
                            }
                        })
                        break;
                    case RecordType.UNRAISE_EVENT_MESSAGE_TYPE5: {
                        const message = event
                        events = events.map(d => {
                            if (d.object_id === message.object_id && d.id_event === message.id_event && d.edge === message.edge) {
                                d.raised = message.raised
                                return d
                            } else {
                                return d
                            }
                        })
                        break;
                    }
                    default: {
                        console.warn('Unknown message type', event);
                        break;
                    }
    
                }
            })
            let isEventRemoved = false
    
            events = events.filter(event => {
                if (event.raised === false) isEventRemoved = true
                return event.raised !== false
            })
    
            if (isEventRemoved) {
                setTimeout(() => {
                    if (this.eventsSubject.value.length < this.eventsFiltersSubject.value.limit
                        && this.totalSubject.value > this.eventsFiltersSubject.value.limit) {
                        this.getEvents(true)
                    }
                }, 1000);
            }
    
            const sorting = this.eventsFiltersSubject.value.sorting
            if (sorting) {
                events = this.sortEvents(events, sorting)
            }
            this.eventsSubject.next(events)
        }
        
    }

    private sortEvents(iEvents: IEvent[], sorting: SortingIds[]) {
        return iEvents.sort((a, b) => {
            for (let i = 0; i < sorting.length; i++) {
                switch (sorting[i]) {
                    case SortingIds.priority: {
                        const aPriority = this.eventTypesService.getById(a.event_class_id).priority
                        const bPriority = this.eventTypesService.getById(b.event_class_id).priority
                        if (aPriority < bPriority) {
                            return 1
                        } else if (aPriority > bPriority) {
                            return -1
                        }
                        break;
                    }
                    case SortingIds.activity: {
                        const aActive = EventsService.isEventActive(a)
                        const bActive = EventsService.isEventActive(b)
                        if (aActive && !bActive) {
                            return -1
                        } else if (!aActive && bActive) {
                            return 1
                        }
                        break;
                    }
                    case SortingIds.check: {
                        const aCheck = EventsService.isEventChecked(a)
                        const bCheck = EventsService.isEventChecked(b)
                        if (aCheck && !bCheck) {
                            return 1
                        } else if (!aCheck && bCheck) {
                            return -1
                        }
                        break;
                    }
                }
            }
            return 0
        })
    }
}
