import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IObject, TypesUtils} from "@atl/lacerta-ui-common";
import {UrlService} from "@atl/shared/services";

@UntilDestroy()
@Component({
    selector: 'lta-tree-object-item',
    templateUrl: 'tree-object-item.component.html',
    styleUrls: ['tree-object-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeObjectItemComponent {

    @Input() object: IObject;
    @Input() active: boolean;

    public getIconByModelId = TypesUtils.getIconByModelId

    constructor(
        public urlService: UrlService,
    ) {
    }
}

