import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'lta-logo',
    templateUrl: 'logo.component.html',
    styleUrls: ['logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
    @Input() type: 'small' | 'wide' | 'login' | 'runtime' = 'wide'

    public get path() {
        switch (this.type) {
            case 'login': {
                return '/assets/logo/logo_login.svg'
            }
            case 'small': {
                return '/assets/logo/logo.svg'
            }
            case 'wide': {
                return '/assets/logo/logo_full.svg'
            }
            case "runtime": {
                return '/assets/logo/logo_runtime.svg'
            }
        }
    }
}
