import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MainPageService} from "@atl/main/main-page/main-page.service";
import {catchError, first, switchMap, tap} from "rxjs/operators";
import {HmiService} from '@app/@atl/administration/hmi/services';
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";
import {IVideoScreenRuntime, TypeVideoScreen} from "@atl/admin/hmi/interfaces";
import {of} from "rxjs";
import {UrlService} from "@atl/shared/services";

export const ARGUMENTS_QUERY_PARAM = 'args'

@UntilDestroy()
@Component({
    selector: 'lta-hmi-page',
    templateUrl: 'hmi-page.component.html',
    styleUrls: ['hmi-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HmiService]
})
export class HmiPageComponent implements OnInit {
    @ViewChild('workArea', {static: true}) workArea: ElementRef;
    @ViewChild("placeholderTemplate", {read: ViewContainerRef}) placeholderTemplate: ViewContainerRef;

    public videoScreen$ = this.hmiService.videoScreenRuntime$
    public loading$ = this.mainPageService.loading$

    constructor(
        private hmiService: HmiService,
        private route: ActivatedRoute,
        private mainPageService: MainPageService,
        private flashMessagesService: FlashMessagesService,
        private cd: ChangeDetectorRef,
        private urlService: UrlService
    ) {
    }

    public isCompositeType(videoScreen: IVideoScreenRuntime) {
        return videoScreen.type === TypeVideoScreen.Composite
    }

    ngOnInit(): void {
        this.route.params
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    this.mainPageService.setLoadingStatus(true);
                    this.mainPageService.setTitle(null);
                }),
                switchMap(({id}) => {
                    const args = this.route.snapshot.queryParamMap.get(ARGUMENTS_QUERY_PARAM)
                    if (!id) return of(null)
                    return this.hmiService.getVideoScreenRuntimeById(Number(id), args && args.split(',').map(value => parseInt(value, 10)))
                        .pipe(
                            first(),
                            untilDestroyed(this),
                            catchError(() => {
                                this.urlService.goBack()
                                return of(null)
                            })
                        )
                }),
            )
            .subscribe((vs) => {
                this.mainPageService.setLoadingStatus(false)
                if (!vs) return
                this.mainPageService.setTitle(vs.name)

            })
    }

    public changeHmi($event: { videoScreenId: number; args?: number[] }) {
        this.hmiService.getVideoScreenRuntimeById($event.videoScreenId, $event.args)
            .subscribe()
    }
}
