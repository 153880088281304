import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IEditComponent, ILtaComponent} from "@atl/admin/models/interfaces";
import {BaseHttp, IModelShort} from "@atl/lacerta-ui-common";

@Injectable({
    providedIn: 'root'
})
export class ComponentsHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    getComponents(withoutSvg: boolean = false): Observable<ILtaComponent[]> {
        const url = `${this.apiRoot}/components`;
        let params = new HttpParams();
        if (withoutSvg) {
            params = params.append('without_svg', withoutSvg)
        }
        return this.http.get<ILtaComponent[]>(url, {withCredentials: true, params})
    }

    getComponentsWithoutSvg(): Observable<ILtaComponent[]> {
        const url = `${this.apiRoot}/components/without_svg`
        return this.http
            .get<ILtaComponent[]>(url, {withCredentials: true})
    }

    getComponentById(id: number): Observable<ILtaComponent> {
        const url = `${this.apiRoot}/component/${id}`;
        return this.http.get<ILtaComponent>(url, {withCredentials: true})
    }

    updateComponent(id: number, body: IEditComponent): Observable<ILtaComponent> {
        const url = `${this.apiRoot}/component/${id}`;
        return this.http.put<ILtaComponent>(url, body, {withCredentials: true});
    }

    getModelsByComponentId(id: number): Observable<IModelShort[]> {
        const url = `${this.apiRoot}/component/${id}/models`;
        return this.http.get<IModelShort[]>(url, {withCredentials: true});
    }

    deleteComponent(id: number) {
        const url = `${this.apiRoot}/component/${id}`;
        return this.http.delete<IModelShort[]>(url, {withCredentials: true});
    }
}
