import {Inject, Injectable} from '@angular/core';
import {CanLoad} from '@angular/router';
import {UrlService} from "@atl/shared/services/url.service"
import {AuthService, AUTH_SERVICE} from "@atl/lacerta-ui-common";

export const RETURN_URL_QUERY_PARAM = 'returnUrl'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(
        @Inject(AUTH_SERVICE) private authService: AuthService,
        private urlService: UrlService
    ) {
    }

    canLoad() {
        if (!this.authService.isLoggedIn()) {
            let currentUrl = this.urlService.getCurrentUrl()
            this.urlService.goToLogin({queryParams: {[RETURN_URL_QUERY_PARAM]: currentUrl !== '/' ? currentUrl : null}})
        }
        return true
    }
}
