import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable()
export class MainPageService {
    public showPreloaderFlagSubject = new BehaviorSubject<boolean>(true);
    public showPreloaderFlag$ = this.showPreloaderFlagSubject.asObservable();
    private titleSubject = new Subject<string>()
    public title$ = this.titleSubject.asObservable()
    private loadingSubject = new BehaviorSubject(true)
    public loading$ = this.loadingSubject.asObservable()

    constructor() {
    }

    public get isLoading() {
        return this.loadingSubject.value;
    }

    public setTitle(title: string) {
        setTimeout(() => {
            this.titleSubject.next(title)
        })
    }

    public setLoadingStatus(status: boolean) {
        this.loadingSubject.next(status)
    }
}
