import {Injectable} from "@angular/core"
import {IPermission, IUser, UserService} from "@atl/lacerta-ui-common";
import {
    AccessPermissionsEnum,
    PermissionsAccessor,
    RestrictedPages,
    WithPermissionsAccessor
} from "@atl/authorization/services/permissions-accessor";

@Injectable({
    providedIn: 'root'
})
export class UserServiceImpl extends UserService implements WithPermissionsAccessor {
    private permissionAccessor: PermissionsAccessor = new PermissionsAccessor();
    hasPermission = (key: AccessPermissionsEnum) => this.permissionAccessor.hasPermission(key);
    hasPermissionToPage = (page: RestrictedPages) => this.permissionAccessor.hasPermissionToPage(page);
    setPermissions = (permissions: IPermission[]) => this.permissionAccessor.setPermissions(permissions);

    setUser(user: IUser) {
        this.userSubject.next(user);
        this.setPermissions(user?.role?.permissions);
        this.storeUser(user);
    }
}
