import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    createNgModule,
    Inject,
    Injectable,
    Injector,
    NgModuleRef,
    TemplateRef,
    Type,
    ViewContainerRef,
    ViewRef
} from "@angular/core"
import {DOCUMENT} from "@angular/common";

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({providedIn: 'root'})
export class ModalService {
    private idToRefMap = new Map<string, { component: ComponentRef<any>, view: ViewRef }>()

    constructor(
        private applicationRef: ApplicationRef,
        private resolver: ComponentFactoryResolver,
        @Inject(DOCUMENT) private document: Document,
        private injector: Injector
    ) {
    }

    public get container() {
        return this.applicationRef.components[0].injector.get(ViewContainerRef)
    }

    public get<T>(id: string): ComponentRef<T> | null {
        return this.idToRefMap.get(id)?.component
    }

    public create<T>(id: string, componentType: Type<T>, options?: {
        inputs?: Object,
        content?: Content<T>,
        contentContext?: Object,
        module?: Type<any>,
        injector?: Injector
    }): ComponentRef<T> | null {
        if (this.idToRefMap.has(id)) {
            return this.get(id)
        }

        let ngContent = options?.content ? this.resolveNgContent(options.content, options.contentContext || '') : undefined
        let moduleRef: NgModuleRef<T>
        if (options?.module) {
            moduleRef = createNgModule(options.module, options.injector ? options.injector : this.injector);
        }
        const component = this.container.createComponent(componentType, {
            ngModuleRef: moduleRef,
            projectableNodes: ngContent,
        })

        if (options?.inputs) {
            Object.keys(options.inputs).forEach(key => {
                component.instance[key] = options.inputs[key]
            })
        }

        const index = this.container.length - 1

        this.idToRefMap.set(id, {component: component, view: this.container.get(index)})

        return component
    }

    public remove(id: string) {
        this.idToRefMap.get(id)?.view.destroy()
        this.idToRefMap.delete(id)
    }

    private resolveNgContent<T>(content: Content<T>, contentContext: T = null) {
        if (typeof content === 'string') {
            const element = this.document.createTextNode(content);
            return [[element]];
        }

        if (content instanceof TemplateRef) {
            // @ts-ignore
            const viewRef = content.createEmbeddedView({$implicit: contentContext});
            this.applicationRef.attachView(viewRef)
            return [...viewRef.rootNodes.map(v => [v])];
        }

        const factory = this.resolver.resolveComponentFactory(content);
        const componentRef = factory.create(this.injector);
        return [[componentRef.location.nativeElement]];
    }
}
