import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IDriverMapping} from '@app/@atl/administration/drivers/interfaces';
import {Observable} from 'rxjs';
import {ObjectsService} from '@atl/admin/objects/services';
import {IObjectPathItem} from "@atl/lacerta-ui-common";
import {UrlService} from "@atl/shared/services";

@UntilDestroy()
@Component({
    selector: 'lta-tree-mapping-item',
    templateUrl: 'tree-mapping-item.component.html',
    styleUrls: ['tree-mapping-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeMappingItemComponent implements OnInit {

    @Input() mapping: IDriverMapping;

    public path$: Observable<IObjectPathItem[]>;

    constructor(
        private objectsService: ObjectsService,
        public urlService: UrlService,
    ) {
    }

    ngOnInit() {
        this.path$ = this.objectsService.getObjectPath(+this.mapping.obj_id, true);
    }

}

