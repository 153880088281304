<div id="flashMessages" class="flash-messages">
    <div
            *ngFor="let message of messages$ | async; let i = index"
            [@anim]="'active'"
            class="alert flash-message"
            [ngClass]="getClasses(message)">
        <lta-svg-icon
                (click)="close(message)"
                *ngIf="!message.options.isHideCloseButton"
                class="close-icon"
                icon="close"
                size="20"
        ></lta-svg-icon>
        <lta-svg-icon class="type-icon" [icon]="message.content.type" size="40"></lta-svg-icon>
        <div class="alert__content">
            <div
                    *ngIf="message.content.title || message.content.body.title"
                    class="alert__title"
                    translate
            >
                {{message.content.body?.title ? message.content.body.title : message.content.title}}
            </div>
            <div
                    *ngIf="message.content.body"
                    class="alert__body"
                    translate
            >
                {{message.content.body.body ? message.content.body.body : message.content.body}}
            </div>
        </div>
    </div>
</div>
