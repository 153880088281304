import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SaveDialogComponent} from './save-dialog.component';
import {BaseModalModule} from '../base-modal/base-modal.module';
import {TranslateModule} from '@ngx-translate/core';
import {ModalLayoutModule} from '../modal-layout/modal-layout.module';
import {LtaBtnModule, LtaSvgIconModule} from "@atl/lacerta-ui-common";


@NgModule({
    declarations: [SaveDialogComponent],
    exports: [SaveDialogComponent],
    imports: [
        CommonModule,
        BaseModalModule,
        LtaBtnModule,
        LtaSvgIconModule,
        TranslateModule,
        ModalLayoutModule
    ]
})
export class SaveDialogModule {
}

