<div class="main-settings">
    <form [formGroup]="mainSettingsForm">
        <lta-select
                [isLoading]="isLoading"
                [selectOptions]="screensSelect$ | async | sort"
                formControlName="startScreen"
                label="{{ 'adm-settings.start-screen' | translate }}"
                theme="white"
        ></lta-select>
        <lta-select
                [selectOptions]="langSelect"
                formControlName="language"
                label="{{ 'lta-settings.language' | translate }}"
                theme="white"
        ></lta-select>
    </form>
</div>
