<ng-container *ngIf="videoScreen$ | async as videoScreen">
    <lta-hmi-svg
            (onChangeHmi)="changeHmi($event)"
            *ngIf="!isCompositeType(videoScreen)"
            [videoScreen]="videoScreen"
    ></lta-hmi-svg>
    <lta-hmi-composite
            *ngIf="isCompositeType(videoScreen)"
            [videoScreen]="videoScreen"
    ></lta-hmi-composite>
</ng-container>
