import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'lta-delete-dialog',
    templateUrl: 'delete-dialog.component.html',
    styleUrls: ['delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteDialogComponent implements OnInit {
    @Input() deleteBtnText = 'lta.delete';
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

    @Input() set isLoading (value: boolean) {
        this._isloading = value
        this.cd.markForCheck()
    }

    public get isLoading() {
        return this._isloading
    }
    private _isloading: boolean = false

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    onDeleteClicked() {
        this.onDelete.emit();
    }
}
