import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {interval, Subject} from "rxjs";
import {LtaDatePipe} from "@atl/pipes/lta-date/lta-date.pipe";
import {TimeService} from "@atl/shared/services/time.service";

@UntilDestroy()
@Component({
    selector: 'lta-moment-clock',
    templateUrl: 'moment-clock.component.html',
    styleUrls: ['moment-clock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MomentClockComponent implements OnInit {
    public time$ = new Subject<string>()

    constructor(private ltaDatePipe: LtaDatePipe, private timeService: TimeService) {
    }

    ngOnInit() {
        interval(1000)
            .pipe(
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.time$.next(this.ltaDatePipe.transform(this.timeService.getMomentWithTimezone().valueOf()))
            });
    }
}
