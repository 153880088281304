import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UrlService} from "@atl/shared/services/url.service";

@Component({
    selector: 'lta-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls: ['page-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent implements OnInit {

    constructor(private urlService: UrlService) {
    }

    ngOnInit(): void {
    }

    backToThePlatform() {
        // todo: Когда будет готова авторизация, добавить проверку на логин
        const isLogin = true;
        isLogin ? this.urlService.goToHmi() : this.urlService.goToLogin()
    }
}
