<lta-toolbar
        [title]="mainPageService.title$ | async"
></lta-toolbar>
<lta-progress-bar
        *ngIf="(mainPageService.showPreloaderFlag$ | async) && (mainPageService.loading$ | async)"
        [background]="'shadow'"
        top="40"
></lta-progress-bar>
<router-outlet #outlet="outlet"></router-outlet>
<lta-flash-messages></lta-flash-messages>
