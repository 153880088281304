<div
        *ngLet="defaultHMI$ | async as defaultHMI"
        class="toolbar-wrap"
>
    <div class="block border-right">
        <div
                [routerLink]="getUrlVideoFrames(defaultHMI?.id)"
                class="icon-wrapper logo"
        >
            <lta-logo [type]="'runtime'"></lta-logo>
        </div>
    </div>
    <div class="block nav border-right">
        <lta-toolbar-history-navigation></lta-toolbar-history-navigation>
    </div>
    <div class="block border-right">
        <div
                [lta-tooltip]="defaultHMI?.name"
                [ngClass]="{'disabled': !defaultHMI?.id}"
                [position]="'down'"
                [routerLink]="getUrlVideoFrames(defaultHMI?.id)"
                class="icon-wrapper"
                routerLinkActive="active"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="videoframes"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                [lta-tooltip]="'lta.events' | translate"
                [position]="'down'"
                [routerLink]="getUrlAlerts()"
                class="icon-wrapper"
                routerLinkActive="active"
        >
            <span
                    *ngIf="false && (hasRaisedEvents$ | async)"
                    [style.background-color]="lastEventColor$ | async"
                    class="events-count"
            >{{ uncheckedEventsCount$ | async }}</span>
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="alerts"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                [lta-tooltip]="'lta.trends' | translate"
                [position]="'down'"
                [routerLink]="getUrlTrends()"
                class="icon-wrapper"
                routerLinkActive="active"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="trends"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                [lta-tooltip]="'lta.tables' | translate"
                [position]="'down'"
                [routerLink]="getUrlTable()"
                class="icon-wrapper"
                routerLinkActive="active"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="table"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                (click)="openSettings()"
                [lta-tooltip]="'lta.settings' | translate"
                [position]="'down'"
                class="icon-wrapper"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="settings"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                [lta-tooltip]="'lta.vnc' | translate"
                [position]="'down'"
                [routerLink]="getUrlVnc()"
                class="icon-wrapper"
                routerLinkActive="active"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="hmi"
                    size="24"
            ></lta-svg-icon>
        </div>
    </div>
    <div
            #titleContainer
            class="block wide title"
    ><span #title></span></div>
    <div
            class="block border-left"
            style="width: 210px;"
    >
        <lta-moment-clock></lta-moment-clock>
    </div>
    <div class="block border-left">
        <div class="user-info">
            <lta-user-avatar
                    [img]="userPicture$ | async"
                    [theme]="'runtime'"
            ></lta-user-avatar>
            <span class="text-ellipsis user-info__name">{{ (user$ | async)?.name }}</span>
        </div>
        <lta-svg-icon
                (click)="togglePopup()"
                [asCanvas]="true"
                [class.active]="isPopupOpen"
                class="chevron-down"
                icon="chevron-down"
                size="24"
        ></lta-svg-icon>
        <div
                *ngIf="isPopupOpen"
                class="popup"
        >
                <span
                        (click)="onSettingsClick()"
                        class="popup-item"
                >
                    <lta-svg-icon
                            [asCanvas]="true"
                            [icon]="'settings'"
                            class="type-icon"
                            size="24"
                    ></lta-svg-icon>
                    {{ 'menu.settings' | translate }}
                </span>
            <span
                    (click)="onAdministrationClick()"
                    class="popup-item"
            >
                    <lta-svg-icon
                            [asCanvas]="true"
                            [icon]="'admin'"
                            class="type-icon"
                            size="24"
                    ></lta-svg-icon>
                {{ 'lta-menu.administration' | translate }}
                </span>
            <lta-sep class="sep"></lta-sep>
            <span
                    (click)="onDoorClick()"
                    class="popup-item"
            >
                    <lta-svg-icon
                            [asCanvas]="true"
                            [icon]="'door'"
                            class="type-icon"
                            size="24"
                    ></lta-svg-icon>
                {{ 'common.exit' | translate }}
                </span>
        </div>
    </div>
    <div class="block border-left">
        <div
                (click)="onSnapShot()"
                [lta-tooltip]="'common.screenshot' | translate"
                [position]="'down'"
                class="icon-wrapper"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="screenshot"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div *ngIf="false"
                (click)="onSearch()"
                [lta-tooltip]="'lta.objectsSearch' | translate"
                [position]="'down'"
                class="icon-wrapper"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="search"
                    size="24"
            ></lta-svg-icon>
        </div>
        <div
                (click)="onPrint()"
                [lta-tooltip]="'common.print' | translate"
                [position]="'down'"
                class="icon-wrapper"
        >
            <lta-svg-icon
                    [asCanvas]="true"
                    icon="print"
                    size="24"
            ></lta-svg-icon>
        </div>
    </div>
</div>
