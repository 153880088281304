import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ImitationTypes, IObject} from "@atl/lacerta-ui-common";

@UntilDestroy()
@Component({
    selector: 'lta-tree-settings-item',
    templateUrl: 'tree-settings-item.component.html',
    styleUrls: ['tree-settings-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSettingsItemComponent {
    public imitationTypes = ImitationTypes
    @Input() item: IObject;

    constructor() {
    }
}
