import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SelectModalComponent} from "../select-modal/select-modal.component";
import {SearchPanelModule} from "../../search-panel/search-panel.module";
import {TranslateModule} from "@ngx-translate/core";
import {LtaBtnModule} from "@atl/lacerta-ui-common";

@NgModule({
    declarations: [SelectModalComponent],
    exports: [SelectModalComponent],
    imports: [
        CommonModule,
        LtaBtnModule,
        SearchPanelModule,
        TranslateModule,
    ],
})
export class SelectModalModule {
}

