import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseModalComponent} from './base-modal.component';
import {LtaSvgIconModule} from "@atl/lacerta-ui-common";


@NgModule({
    declarations: [BaseModalComponent],
    exports: [BaseModalComponent],
    imports: [
        CommonModule,
        LtaSvgIconModule
    ]
})
export class BaseModalModule {
}
