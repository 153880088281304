<div class="body">
    <div
            *ngIf="!isSearchActive"
            [class.select-modal]="isSelectModal"
            [ngClass]="theme"
            class="title"
    >
        {{ titleText | translate }}
        <div
                (click)="onActivateSearch()"
                *ngIf="!isSearchActive"
                class="icon-wrapper"
        >
            <lta-svg-icon
                    class="icon"
                    icon="search"
                    size="24"
            ></lta-svg-icon>
        </div>
    </div>

    <lta-search
            (onClear)="searchValue = ''; onSearch.emit('')"
            (onSearch)="onSearch.emit($event)"
            (onSearchResultIndexChange)="onSearchResultIndexChange.emit($event)"
            *ngIf="isSearchActive"
            [lta-flash-message]="'lta.limitSearch' | translate"
            [searchError]="searchError"
            [searchResult]="searchResult"
            [theme]="theme"
            class="search"
            [class.full-width]="isFullWidth"
    >
    </lta-search>
</div>
