import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FlashMessagesComponent} from "@atl/modules/flash-messages/components";
import {LtaSvgIconModule} from "@atl/lacerta-ui-common";


@NgModule({
    declarations: [
        FlashMessagesComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        LtaSvgIconModule
    ],
    exports: [
        FlashMessagesComponent
    ],
})
export class FlashMessagesModule {
}
