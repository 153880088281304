import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";


@Injectable()
export class MenuService {
    isOpen = false;
    public openedMenuSet: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set());

    constructor() {
        const {pathname} = window.location;
        const pathnameSegments = pathname.split(`/`);

        if (pathnameSegments[1]) {
            this.toggleOpened(pathnameSegments[1]);
        }
    }

    public toggleOpened(menuId: string) {
        const {value: menuSet} = this.openedMenuSet;

        if (this.isOpened(menuId)) {
            menuSet.delete(menuId);
        } else {
            menuSet.add(menuId);
        }

        this.openedMenuSet.next(menuSet);
    }

    public isOpened(menuId) {
        const {value: menuSet} = this.openedMenuSet;
        return menuSet.has(menuId);
    }

    toggleMainMenu() {
        this.isOpen = !this.isOpen;
    }

    closeMainMenu() {
        this.isOpen = false;
    }
}
