import {Injectable} from '@angular/core';
import {TranslatePipe} from "@ngx-translate/core";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";
import {ErrorInterceptor} from "@atl/lacerta-ui-common"

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ErrorInterceptorImpl extends ErrorInterceptor {
    constructor(private flashMessagesService: FlashMessagesService, private translatePipe: TranslatePipe) {
        super()
    }

    handleUnknownError(err: any) {
        this.flashMessagesService.showError(err)
    }

    handle502(error: any) {
        const err = {message: this.translatePipe.transform('lta-errors.serverUnavailable')}
        this.flashMessagesService.showError(err)
        throw err
    }

    handle504(error: any) {
        const err = {message: this.translatePipe.transform('lta-errors.timeout')}
        this.flashMessagesService.showError(err)
        throw err
    }
}
