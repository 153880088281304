import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
    IUpdateVSInput,
    IVideoScreen,
    IVideoScreenArgument,
    IVideoScreenArgumentChild,
    IVideoScreenElement,
    IVideoScreenElementRuntime, IVideoScreenFolder, IVideoScreenFolderContent, IVideoScreenFoldersTreeNode,
    IVideoScreenRuntime,
    IVideosScreenObject
} from '../interfaces';
import {HmiHttpService} from "@atl/admin/hmi/services/hmi-http.service";
import {tap} from "rxjs/operators";
import {cloneDeep, flattenDeep} from "lodash";
import {IObject, Types, ValueType, WebsocketService, WebsocketValuesSessionClass} from "@atl/lacerta-ui-common"

@Injectable()
export class HmiService {
    public valuesObservable$: Observable<ValueType>
    private videoScreensSubject: BehaviorSubject<IVideoScreen[]> = new BehaviorSubject<IVideoScreen[]>(null);
    public videoScreens$: Observable<IVideoScreen[]> = this.videoScreensSubject.asObservable();
    private videoScreenSubject: Subject<IVideoScreen> = new Subject<IVideoScreen>();
    public videoScreen$: Observable<IVideoScreen> = this.videoScreenSubject.asObservable();
    private videoScreenRuntimeSubject: Subject<IVideoScreenRuntime> = new Subject<IVideoScreenRuntime>();
    public videoScreenRuntime$: Observable<IVideoScreenRuntime> = this.videoScreenRuntimeSubject.asObservable();

    private foldersSubject: BehaviorSubject<IVideoScreenFolder[]> = new BehaviorSubject<IVideoScreenFolder[]>(null);
    public folders$: Observable<IVideoScreenFolder[]> = this.foldersSubject.asObservable();

    private valuesSession: WebsocketValuesSessionClass

    constructor(private hmiHttpService: HmiHttpService, private websocketService: WebsocketService) {
        this.valuesSession = this.websocketService.startValuesSession([], true)
        this.valuesObservable$ = this.valuesSession.valuesObservable$
    }

    static deconstructVideoScreenObject(obj: IVideosScreenObject): IVideosScreenObject[] {
        if (!obj) return []
        const copy = cloneDeep(obj)
        const extractChildren = (obj: IVideosScreenObject): IVideosScreenObject[] => {
            if (!obj?.children) return [obj]
            const children = [...obj.children]
            delete obj.children
            return [obj, ...flattenDeep(children.map(obj => extractChildren(obj)))]
        }
        return extractChildren(copy).filter(obj => obj.type_id !== Types.Dir)
    }

    public createVideoScreen(videoScreen: IVideoScreen): Observable<IVideoScreen> {
        return this.hmiHttpService.createVideoScreen(videoScreen);
    }

    public createFolder(parentId: number, name: string): Observable<IVideoScreenFolder> {
        return this.hmiHttpService.createFolder(parentId, name);
    }

    public subscribeVS(vs: IVideoScreenRuntime) {
        const objectsIds = this.getAllObjectsIds(vs.elements)
        this.valuesSession.addSubscriptions(objectsIds)
    }

    public unsubscribeVS(vs: IVideoScreenRuntime) {
        const objectsIds = this.getAllObjectsIds(vs.elements)

        this.valuesSession.removeSubscription(objectsIds)
    }

    public getFolderContentById(id: number, withoutSvg: boolean = false): Observable<IVideoScreenFolderContent> {
        return this.hmiHttpService.getFolderContentById(id, withoutSvg).pipe(tap(content => {
            this.foldersSubject.next(content.folders);
            this.videoScreensSubject.next(content.hmi);
        }))
    }

    public getFoldersTree(rootId: number = 1): Observable<IVideoScreenFoldersTreeNode> {
        return this.hmiHttpService.getFoldersTree(rootId);
    }

    public getVideoScreens(withoutSvg: boolean = false): Observable<IVideoScreen[]> {
        return this.hmiHttpService.getVideoScreens(withoutSvg).pipe(tap(v => {
            this.videoScreensSubject.next(v)
        }))
    }

    public getVideoScreenById(id: number): Observable<IVideoScreen> {
        return this.hmiHttpService
            .getVideoScreenById(id)
            .pipe(
                tap((vs) => {
                    this.videoScreenSubject.next(vs)
                    if (!this.videoScreensSubject.value) return;
                    const updatedVSs = this.videoScreensSubject.value.map(v => {
                        if (v.id !== id) return v
                        return vs
                    })
                    this.videoScreensSubject.next(updatedVSs)
                })
            )

    }

    public getVideoScreenRuntimeById(id: number, args?: number[]): Observable<IVideoScreenRuntime> {
        return this.hmiHttpService
            .getVideoScreenRuntimeById(id, args).pipe(tap((vs) => {
                this.videoScreenRuntimeSubject.next(vs)
            }))
    }

    public bindElementToObject(
        element: IVideoScreenElement,
        object: IObject
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToObject(element, object)
    }

    public bindElementToArgument(
        element: IVideoScreenElement,
        argument: IVideoScreenArgument
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToArgument(element, argument)
    }

    public bindElementToArgumentChild(
        element: IVideoScreenElement,
        argument: IVideoScreenArgumentChild
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToArgumentChild(element, argument)
    }

    public unbindElement(element: IVideoScreenElement): Observable<any> {
        return this.hmiHttpService.unbindElement(element)
    }

    public updateVideoScreenElement(id: number, obj: IVideoScreenElement): Observable<IVideoScreenElement> {
        return this.hmiHttpService.updateVideoScreenElement(id, obj)
    }

    public updateFolder(folder: IVideoScreenFolder): Observable<IVideoScreenFolder> {
        return this.hmiHttpService.updateFolder(folder);
    }

    public updateVideoScreen(id: number, body: IUpdateVSInput): Observable<IVideoScreen> {
        return this.hmiHttpService.updateVideoScreen(id, body)
    }

    public addVideoScreenArgument(id: number, body: { model: number }): Observable<IVideoScreenArgument> {
        return this.hmiHttpService.addVideoScreenArgument(id, body)
    }

    public deleteVideoScreenArgument(id: number, argumentId: number): Observable<null> {
        return this.hmiHttpService.deleteVideoScreenArgument(id, argumentId)
    }

    public deleteFolder(id: number): Observable<void> {
        return this.hmiHttpService.deleteFolder(id)
            .pipe(tap(x => {
                this.foldersSubject.next(this.foldersSubject.value.filter(f => f.id !== id))
            }))
    }

    public deleteVideoScreen(id: number): Observable<null> {
        return this.hmiHttpService.deleteVideoScreen(id)
            .pipe(tap(x => {
                this.videoScreensSubject.next(this.videoScreensSubject.value.filter(vs => vs.id !== id))
            }))
    }

    public moveItemsToFolder(folderId: number, items: {folders: number[], hmi: number[]}): Observable<void> {
        return this.hmiHttpService.moveItemsToFolder(folderId, items)
            .pipe(tap(x => {
                this.foldersSubject.next(this.foldersSubject.value.filter(f => !items.folders.includes(f.id)))
                this.videoScreensSubject.next(this.videoScreensSubject.value.filter(vs => !items.hmi.includes(vs.id)))
            }))
    }

    private getAllObjectsIds(elements: IVideoScreenElementRuntime[]) {
        if (!elements) return []
        const allObjectsIds = new Set<number>()
        elements.forEach(el => HmiService.deconstructVideoScreenObject(el.object)
            .forEach(obj => allObjectsIds.add(obj.id)))

        return Array.from(allObjectsIds)
    }
}
