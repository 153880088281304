import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class FocusService {
    private focusSubject = new Subject<string>();
    public focus$ = this.focusSubject.asObservable();

    public focusOnId(id: string) {
        this.focusSubject.next(id);
    }

    public listenToFocus(id: string): Observable<string> {
        return this.focus$.pipe(filter(id => id === id));
    }

}
