<div
        (click)="goTo(vs)"
        *ngFor="let vs of filterItems(item.video_screens)"
        class="item"
>
    <lta-svg-icon
            [icon]="'hmi'"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
    <span class="item__name">{{ vs.name }}</span>
</div>
