import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'lta-user-avatar',
    templateUrl: 'user-avatar.component.html',
    styleUrls: ['user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
    @ViewChild('avatar') avatar: ElementRef;

    @Input() theme: 'square' | 'admin' | 'runtime' | 'list'

    constructor(private renderer: Renderer2) {
    }

    public _img: string;

    @Input() set img(img: string) {
        this._img = img
        if (!img) {
            this.deleteImg()
            return
        }

        this.setImg(img)
    };

    private deleteImg() {
        if (this.avatar) this.renderer.removeStyle(this.avatar.nativeElement, 'background-image');
    }

    private setImg(img) {
        setTimeout(() => {
            this.renderer.setStyle(this.avatar.nativeElement, 'background-image', 'url(' + img + ')');
        })
    }
}
