import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'lta-sep',
    templateUrl: 'separator.component.html',
    styleUrls: ['separator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatorComponent {

    constructor() {
    }
}
