<lta-modal-layout
        (escapePress)="onCancel.emit()"
        (layoutClick)="onCancel.emit(); $event.stopImmediatePropagation()"
>
    <lta-modal  [ngClass]="theme" class="modal">
        <lta-select-modal
                (onCancel)="onCancel.emit()"
                (onSearch)="onSearch($event)"
                (onSearchResultIndexChange)="tree.setFocus(searchResult[$event - 1])"
                (onSubmit)="onSubmit.emit(selected)"
                [isInsertDisabled]="!selected"
                [searchError]="searchError$ | async"
                [searchResult]="searchResult.length"
                [titleText]="titleText"
                [theme]="theme"
        >
            <lta-tree
                    #tree
                    [filterString]="treeFilter"
                    [options]="treeOptions"
                    
            ></lta-tree>
            <lta-preloader *ngIf="isLoadingSearchResult"></lta-preloader>
        </lta-select-modal>
    </lta-modal>
</lta-modal-layout>
