import {animate, sequence, style, transition, trigger} from '@angular/animations';
import {Component, Injector} from '@angular/core';

import {FlashMessageCurrent, FlashMessagesService, FlashMessageType} from '../../services/flash-messages.service';
import {Observable} from 'rxjs';


@Component({
    selector: 'lta-flash-messages',
    styleUrls: ['flash-messages.component.scss'],
    templateUrl: 'flash-messages.component.html',
    animations: [
        trigger('anim', [
            transition('* => void', [
                style({opacity: '1', transform: 'translateX(0)'}),
                sequence([
                    animate('.25s ease', style({opacity: '.2', transform: 'translateX(20px)'})),
                    animate('.1s ease', style({opacity: 0, transform: 'translateX(20px)'}))
                ])
            ]),
            transition('void => active', [
                style({opacity: '0', transform: 'translateX(20px)'}),
                sequence([
                    animate('.1s ease', style({opacity: '.2', transform: 'translateX(20px)'})),
                    animate('.35s ease', style({opacity: 1, transform: 'translateX(0)'}))
                ])
            ])
        ])
    ]
})
export class FlashMessagesComponent {
    public messages$: Observable<FlashMessageType[]> = this.flashMessagesService.messages$;

    constructor(
        private flashMessagesService: FlashMessagesService,
        private injector: Injector
    ) {
    }

    getInjector(message: FlashMessageType) {
        return Injector.create({
            providers: [{provide: FlashMessageCurrent, useValue: message, deps: []}],
            parent: this.injector
        });
    }

    close(message: FlashMessageType): void {
        this.flashMessagesService.close(message);
    }

    getClasses(message: FlashMessageType): string[] {
        return message.options.classes ? message.options.classes : [];
    }
}
