import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LtaTreeComponent} from "@atl/modules/tree/tree.component";
import {TranslateModule} from "@ngx-translate/core";
import {TreeModule} from "@ali-hm/angular-tree-component";
import {TreeMappingItemComponent} from "@atl/modules/tree/components/tree-mapping-item/tree-mapping-item.component";
import {TreeSettingsItemComponent} from "@atl/modules/tree/components/tree-settings-item/tree-settings-item.component";
import {TreeObjectMnemoItemComponent} from "@atl/modules/tree/components/tree-object-mnemo-item/tree-object-mnemo-item.component";
import {LtaContextMenuComponent} from "@atl/modules/context-menu/context-menu.component";
import {LtaInfoPopupModule, LtaObjectHierarchyModule, LtaSvgIconModule} from "@atl/lacerta-ui-common";
import {TooltipDirectiveModule} from '@app/@atl/directives/tooltip-directive/tooltip-directive.module';
import {TreeSelectionItemComponent} from "@atl/modules/tree/components/tree-selection-item/tree-selection-item.component";
import {TreeObjectItemComponent} from "@atl/modules/tree/components/tree-object-item/tree-object-item.component";
import {TreeBindObjectItemComponent} from "@atl/modules/tree/components/tree-bind-object-item/tree-bind-object-item.component";

@NgModule({
    declarations: [LtaTreeComponent, TreeSettingsItemComponent, TreeMappingItemComponent, TreeObjectMnemoItemComponent, TreeSelectionItemComponent, TreeObjectItemComponent, TreeBindObjectItemComponent],
    exports: [LtaTreeComponent],
    imports: [
        CommonModule,
        LtaSvgIconModule,
        TranslateModule,
        TreeModule,
        LtaInfoPopupModule,
        LtaObjectHierarchyModule,
        LtaContextMenuComponent,
        TooltipDirectiveModule
    ]
})
export class LtaTreeModule {
}
