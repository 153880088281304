<lta-header-layout *ngLet="activeObject$ | async as activeObject">
    <lta-header-panel header>
        <lta-search-widget
                (onClickFilter)="onSearch($event)"
                (onSearch)="onSearch($event)"
                (onSearchResultIndexChange)="tree.setFocus(searchResult[$event - 1])"
                [searchError]="searchError$ | async"
                [searchResult]="searchResult.length"
                class="search"
        ></lta-search-widget>
        <div class="actions">
            <lta-btn
                    (onClick)="restoreObject(activeObject)"
                    *ngIf="canRestore(activeObject?.model?.id)"
                    [disabled]="isCompositeRestore(activeObject?.model?.id)"
                    size="md"
                    theme="primary"
            >
                <lta-svg-icon
                        icon="composite"
                        size="20"
                ></lta-svg-icon>
                &nbsp;
                {{ 'lta.restore' | translate }}
            </lta-btn>
            <lta-btn
                    (onClick)="addObject()"
                    [btnIcon]="true"
                    [disabled]="canCreateObject(activeObject)"
                    [lta-tooltip]="'lta.create' | translate"
                    position="down"
                    size="md"
                    theme="primary"
            >
                <lta-svg-icon
                        icon="plus"
                        size="20"
                ></lta-svg-icon>
            </lta-btn>
            <lta-btn
                    (onClick)="createDeleteDialog(activeObject)"
                    *ngIf="activeObject"
                    [btnIcon]="true"
                    [disabled]="canCreateObject(activeObject)"
                    [lta-tooltip]="'lta.delete' | translate"
                    position="down"
                    size="md"
                    theme="danger"
            >
                <lta-svg-icon
                        icon="trash"
                        size="20"
                ></lta-svg-icon>
            </lta-btn>
        </div>
    </lta-header-panel>
    <ng-container body>
        <lta-aside-layout [isEqualColumns]="isFullScreenCalculatorMode">
            <ng-container main>
                <lta-empty-panel>
                    <div class="tree-container">
                        <lta-tree
                                #tree
                                *ngLet="treeOptions$ | async as treeOptions"
                                [filterString]="treeFilter"
                                [options]="treeOptions"
                        ></lta-tree>
                    </div>
                </lta-empty-panel>
            </ng-container>
            <ng-container aside>
                <lta-object-info-panel
                        (onAddLink)="createBindObjectDialog($event)"
                        (onCancel)="onCancel()"
                        (onCreateObject)="createObject($event)"
                        (onFullScreen)="toggleFullScreen($event)"
                        (onRemoveLink)="unbindLink($event)"
                        (onUpdateObject)="updateObject($event)"
                        (showFormulaSelect)="showObjectSelectForFormula$.next($event)"
                        [addObjectToFormula]="addObjectToFormula$ | async"
                        [driversSelectOptions]="driversSelectOptions$ | async"
                        [drivers]="drivers$ | async"
                        [eventTypesSelectOptions]="eventTypesSelectOptions$ | async"
                        [isLoading]="isLoading$ | async"
                        [linkedSiblings]="activeLinkedObjectSiblings$ | async"
                        [modelsSelectOptions]="modelsSelectOptions$ | async"
                        [object]="activeObject"
                        [saveState]="saveState"
                        [siblings]="activeObjectSiblings$ | async"
                        [websocketValue]="websocketValue$ | async"
                ></lta-object-info-panel>
            </ng-container>
        </lta-aside-layout>
    </ng-container>
</lta-header-layout>

<ng-template
        #deleteDialogContent
        let-activeObject
>
    <p class="modal-message">
        {{ 'lta-modal.Are you sure you want to delete object' | translate }}
        <span class="no-wrap">
                    <span class="current-item text-ellipsis">{{ activeObject?.name }}</span>?
                </span>
        <br>
        <span>
                {{ 'lta-modal.When deleting the object,' | translate }}
            <span class="no-wrap">
                    <span class="current-item text-ellipsis">{{ activeObject?.name }}</span
                    >
                </span>
            {{ 'lta-modal.the objects nested in it will also be deleted' | translate }}
            </span>
    </p>

    <lta-checkbox-runtime
            [formControl]="forceDelete"
            [size]="24"
            class="footer checkbox"
            theme="light"
    >
        {{ 'lta.forceDeleteObject' | translate }}
    </lta-checkbox-runtime>
</ng-template>
