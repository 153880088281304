import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy'
import {RuntimeModalComponent} from "@atl/modules/modals/runtime-modal/runtime-modal.component";
import {TranslateModule} from "@ngx-translate/core";
import {ISearchObjectTreeItem, LtaBtnModule, ObjectsHttpService, TypesUtils} from "@atl/lacerta-ui-common";
import {SearchModule} from "@atl/modules/form-controls/search/search.module";
import {AsyncPipe, CommonModule} from "@angular/common";
import {FlashMessageDirectiveModule} from "@atl/directives/flash-message-directive/flash-message-directive.module";
import {forkJoin, Subject} from "rxjs";
import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";
import {LtaTreeModule} from "@atl/modules/tree/lta-tree.module";
import {ILtaNodeData, ILtaTreeOptions, LayoutType, TreeMode} from "@atl/modules/tree/interfaces/tree.interface";
import {ObjectsService} from "@atl/admin/objects/services";
import {LtaTreeComponent} from "@atl/modules/tree/tree.component";
import {HmiHttpService} from "@atl/admin/hmi/services";
import {IVideoScreen} from "@atl/admin/hmi/interfaces";
import {UrlService} from "@atl/shared/services";
import {ADMIN_TREE} from "@atl/shared/interfaces/url.interfaces";
import {OBJECT_ID_QUERY_PARAM} from "@atl/admin/objects/components/objects-page/objects-page.component";

export interface ISearchObjectTreeItemWithHMI extends ISearchObjectTreeItem {
    video_screens?: IVideoScreen[]
}

@UntilDestroy()
@Component({
    selector: 'lta-object-search-modal',
    templateUrl: 'object-search-modal.component.html',
    styleUrls: ['object-search-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        RuntimeModalComponent,
        TranslateModule,
        LtaBtnModule,
        SearchModule,
        AsyncPipe,
        FlashMessageDirectiveModule,
        LtaTreeModule
    ],
    standalone: true
})
export class ObjectSearchModalComponent {
    public onCancel: EventEmitter<void> = new EventEmitter<void>();
    public searchError$ = new Subject<Error>()
    public searchResults = []
    public searchValue: string = '';
    public treeOptions: ILtaTreeOptions = {
        mode: TreeMode.SingleSelect,
        theme: 'dark-object-tree',
        sortFn: ObjectsService.sort,
        nameClickAction: (node: ILtaNodeData) => {
            this.urlService.goToAdmin({
                branch: ADMIN_TREE.objects,
                queryParams: {[OBJECT_ID_QUERY_PARAM]: node.item.id},
                target: '_blank'
            })
        }
    }
    @ViewChild('tree') tree: LtaTreeComponent

    public showTree = false

    constructor(private objectsHttpService: ObjectsHttpService, private hmiHttpService: HmiHttpService, private urlService: UrlService, private cd: ChangeDetectorRef) {
    }

    scrollTo(id: IDType) {
        this.tree.setFocus(id)
    }

    public searchHandler(string: string) {
        this.showTree = false

        if (!string) {
            this.searchResults = []
            this.cd.markForCheck()
            return
        }

        this.objectsHttpService.searchForObjectTree(string, 50).pipe(untilDestroyed(this)).subscribe({
            next: tree => {
                if (!tree) return
                this.showTree = true
                this.cd.markForCheck()
                setTimeout(() => {
                    const vsIds: number[] = this.getUniqIds(tree)
                    if (!vsIds.length) {
                        this.tree.nodes = tree.map(item => this.toTreeItem(item, null))
                        return
                    }
                    forkJoin(vsIds.map(id => this.hmiHttpService.getVideoScreenById(id))).subscribe(value => {
                        const vsMap = new Map<number, IVideoScreen>()
                        value.forEach(vs => {
                            vsMap.set(vs.id, vs)
                        })
                        this.tree.nodes = tree.map(item => this.toTreeItem(item, vsMap))
                    })

                    this.searchError$.next(null)
                })
            },
            error: error => {
                this.searchError$.next(error)
            }
        })
    }

    private toTreeItem(obj: ISearchObjectTreeItemWithHMI, vsMap: Map<number, IVideoScreen>): ILtaNodeData {
        obj.video_screens = obj.video_screen_ids?.map(id => vsMap.get(id))
        return {
            id: obj.id,
            item: obj,
            children: obj.childs ? obj.childs.map(child => this.toTreeItem(child, vsMap)) : null,
            icon: TypesUtils.getIconByModelId(obj.type_id),
            name: obj.name,
            useLayout: [LayoutType.ObjectDescriptionLayout, LayoutType.ObjectMnemoLayout],
            isExpanded: !!obj.childs
        }
    }

    private getUniqIds(tree: ISearchObjectTreeItem[]) {
        const vsIds: number[] = []
        const collectIds = (item: ISearchObjectTreeItem) => {
            this.searchResults.push(item.id)

            if (item.video_screen_ids) {
                item.video_screen_ids.forEach(id => vsIds.push(id))
            }
            if (item.childs) {
                item.childs.forEach(child => collectIds(child))
            }
        }

        tree.forEach(i => collectIds(i))
        const vsIdsSet = new Set(vsIds)

        return Array.from(vsIdsSet)
    }

}
