import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ISelection} from "@atl/modules/signal-selector/interfaces/selection.interface";

@UntilDestroy()
@Component({
    selector: 'lta-tree-selection-item',
    templateUrl: 'tree-selection-item.component.html',
    styleUrls: ['tree-selection-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectionItemComponent {

    @Input() selection: ISelection & { limit?: number };

    constructor() {
    }

}

