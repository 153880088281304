<lta-runtime-modal (onCancel)="onCancel.emit()">
    <span
            class="header"
            header
    >{{ 'lta.findObject' | translate }}</span>
    <div
            body
            class="body"
    >
        <lta-search
                (onClear)="searchHandler('')"
                (onSearch)="searchHandler($event)"
                (onSearchResultIndexChange)="scrollTo(searchResults[$event - 1])"
                [clear]="true"
                [lta-flash-message]="'lta.limitSearch' | translate"
                [searchError]="searchError$ | async"
                [searchResult]="searchResults.length"
                [value]="searchValue"
                class="search"
                theme="dark"
        >
        </lta-search>
        <div class="tree-wrapper">
            <lta-tree
                    #tree
                    *ngIf="showTree"
                    [filterString]="searchValue"
                    [options]="treeOptions"
            >
            </lta-tree>
        </div>
    </div>
    <div
            class="footer"
            footer
    >
        <lta-btn
                (onClick)="onCancel.emit()"
                size="md"
                theme="dark cancel"
        >
            {{ 'lta.close' | translate }}
        </lta-btn>
    </div>
</lta-runtime-modal>
