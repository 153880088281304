import {DriverTreeTypes, IDriverTreeItem} from '@atl/admin/drivers/interfaces';
import {IObject, IObjectPathItem} from "@atl/lacerta-ui-common";
import {TrendData} from "@atl/main/trends/interfaces/trend-interface";
import {ILtaNodeData, LayoutType} from "@atl/modules/tree/interfaces/tree.interface";

export class TypesUtils {
    static getIconByDriverTreeType(type: DriverTreeTypes) {
        switch (type) {
            case DriverTreeTypes.SERVER_TYPE_FLOAT64:
                return 'float';
            case DriverTreeTypes.SERVER_TYPE_BOOL:
                return 'bool';
            case DriverTreeTypes.SERVER_TYPE_STRING:
                return 'abc';
            default:
                return 'folder'
        }
    }

    static driverTreeItemToNodeData(driverTreeItem: IDriverTreeItem): ILtaNodeData {
        const treeItem: ILtaNodeData = {
            item: driverTreeItem,
            selectable: true,
            name: driverTreeItem.name,
            hasChildren: driverTreeItem.type === DriverTreeTypes.SERVER_TYPE_UNKNOWN,
            id: driverTreeItem.object_id,
            children: driverTreeItem.childs?.length ? driverTreeItem.childs?.map(d => this.driverTreeItemToNodeData(d)) : null,
            icon: TypesUtils.getIconByDriverTreeType(driverTreeItem.type)
        };

        if (driverTreeItem.mapping) {
            treeItem.useLayout ? treeItem.useLayout.push(LayoutType.DriverMappingLayout) : treeItem.useLayout = [LayoutType.DriverMappingLayout];
        }

        return treeItem;
    }
}

export function isLeftButton(event: MouseEvent | TouchEvent): boolean {
    if (event.type === 'touchstart') {
        return true;
    }
    return (event.type === 'mousedown' && (event as MouseEvent).button === 0);
}

export function getEvent(event: MouseEvent | TouchEvent): MouseEvent | Touch {
    if (event.type === 'touchend' || event.type === 'touchcancel') {
        return (event as TouchEvent).changedTouches[0];
    }
    return event.type.startsWith('touch') ? (event as TouchEvent).targetTouches[0] : event as MouseEvent;
}

export function maxZIndex(selectors: string = 'body *'): number {
    return Array.from(document.querySelectorAll(selectors))
        .map(a => parseFloat(window.getComputedStyle(a).zIndex))
        .filter(a => !isNaN(a))
        .sort((a, b) => a - b)
        .pop() || 0;
}

export function findAncestor(el, selectors): any {
    if (typeof el.closest === 'function') {
        return el.closest(selectors) || null;
    }
    while (el) {
        if (el.matches(selectors)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

export function addTypeToPath(objectPath: IObjectPathItem[], object: IObject): IObjectPathItem[] {
    if (!objectPath || !object) return []
    return objectPath.map(value => {
        if (value.id === object.id) {
            return {
                ...value,
                type: object.type,
                descr: object.descr
            }
        }
        return value
    })
}

export function splitDataByInterval(data: TrendData[], startDate: number, interval: number): TrendData[][] {
    let result = [];
    let currentInterval = [];

    for (let i = 0; i < data.length; i++) {
        const timestamp = data[i].date;

        // Check if the timestamp is within the interval
        if (timestamp >= startDate && timestamp < startDate + interval) {
            currentInterval.push(data[i]);
        } else if (timestamp >= startDate + interval) {
            // Move to the next interval and reset currentInterval
            result.push(currentInterval);
            currentInterval = [data[i]];
            startDate += interval;
        }
    }

    // Add the last interval to the result
    result.push(currentInterval);

    return result;
}

export function filterMinAndMaxData(data: TrendData[]): TrendData[] {
    if (data.length > 1) {
        let min = Math.min(...data.map(item => item.value))
        let max = Math.max(...data.map(item => item.value))
        if (min === max) return [data.find(value => value.value === min)]
        return [...data.filter(item => item.value === min || item.value === max)]
    }
    return data
}

export function findDuplicatesWidthIndexes(arr: string[]): { [key: string]: number[] } {
    const duplicates: { [key: string]: number[] } = {};

    arr.forEach((item, index) => {
        if (duplicates[item]) {
            duplicates[item].push(index);
        } else {
            duplicates[item] = [index];
        }
    });

    // Filter out items that are not duplicates
    Object.keys(duplicates).forEach((key) => {
        if (duplicates[key].length === 1) {
            delete duplicates[key];
        }
    });

    return duplicates;
}

export function arraysEqual(a, b, fieldName?: string) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    if (fieldName) {
        const aClone = a.map(item => item[fieldName]);
        const bClone = b.map(item => item[fieldName]);
        return arraysEqual(aClone, bClone);
    }
    const aClone = a.sort();
    const bClone = b.sort();

    for (var i = 0; i < a.length; ++i) {
        if (aClone[i] !== bClone[i]) return false;
    }
    return true;
}

export function sortByField<T, U>(items: T[], getFieldFn: (item: T) => U): T[] {
    return items.sort((a, b) => getFieldFn(a) === getFieldFn(b) ? 0 : getFieldFn(a) > getFieldFn(b) ? 1 : -1);
}

export function bytesToSizes(bytes: number): { sizes: { [key: string]: number }, largestUnit: string } {
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;
    const TB = GB * 1024;
    const PB = TB * 1024;

    const sizes = {
        KB: +(bytes / KB).toFixed(2),
        MB: +(bytes / MB).toFixed(2),
        GB: +(bytes / GB).toFixed(2),
        TB: +(bytes / TB).toFixed(2),
        PB: +(bytes / PB).toFixed(2)
    };

    const filteredSizes = {};
    for (let unit in sizes) {
        if (sizes[unit] > 0) {
            filteredSizes[unit] = sizes[unit];
        }
    }

    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];

    let largestUnit = '';
    for (let unit of unitsOrder) {
        if (filteredSizes[unit] && filteredSizes[unit] >= 1) {
            largestUnit = unit;
        }
    }

    return {sizes: filteredSizes, largestUnit};
}

export function selectElementContents(el: HTMLElement) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}

export function clearTextSelection() {
    const sel = window.getSelection();
    sel.removeAllRanges();
}

export function mergeObjects(obj, newObj) {
    Object.keys(newObj).forEach(function (key) {
        obj[key] = newObj[key];
    });

    return obj
}
