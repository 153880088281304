export type UrlTarget = '_self' | '_blank'

export interface IUrlOptions<T> {
    target?: UrlTarget,
    id?: number,
    branch?: T,
    queryParams?: Object
}

export enum ADMIN_TREE {
    users = 'users',
    roles = 'roles',
    objects = 'objects',
    models = 'models',
    hmi = 'mnemo',
    hmiComposite = 'mnemo/composite',
    components = 'components',
    driverTypes = 'driver-types',
    drivers = 'drivers',
    cluster = 'cluster',
    alerts = 'alerts',
}

export enum SETTINGS_TREE {
    main = 'main',
    figma = 'figma',
    lang = 'lang',
}

export enum LOGIN_TREE {
    restorePassword = 'restore'
}
