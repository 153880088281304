import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchPanelComponent} from './search-panel.component';
import {TranslateModule} from '@ngx-translate/core';
import {SearchModule} from '../form-controls/search/search.module';
import {FlashMessageDirectiveModule} from "@atl/directives/flash-message-directive/flash-message-directive.module";
import {LtaSvgIconModule} from "@atl/lacerta-ui-common";


@NgModule({
    declarations: [SearchPanelComponent],
    exports: [SearchPanelComponent],
    imports: [
        CommonModule,
        LtaSvgIconModule,
        TranslateModule,
        SearchModule,
        FlashMessageDirectiveModule
    ]
})
export class SearchPanelModule {
}
