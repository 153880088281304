<lta-modal-layout
        (escapePress)="onCancel.emit()"
        (layoutClick)="onCancel.emit()"
>
    <lta-modal class="modal">
        <lta-svg-icon
                class="icon"
                icon="save"
                size="58"
        ></lta-svg-icon>
        <div class="body">
            <ng-content></ng-content>
        </div>
        <p
                class="hint"
        >{{ 'lta-modal.Your changes will be lost if you don’t save them' | translate }}</p>
        <div class="actions">
            <lta-btn
                    (onClick)="onSave.emit()"
                    [disabled]="!canSave"
                    [isLoading]="state === 'SAVING' || state === 'CREATING'"
                    size="md"
                    theme="success"
            >{{ 'lta.save' | translate }}
            </lta-btn>
            <lta-btn
                    (onClick)="onDontSave.emit()"
                    size="md"
                    theme="secondary"
            >{{ 'lta.dontSave' | translate }}
            </lta-btn>
            <lta-btn
                    (onClick)="onCancel.emit()"
                    size="md"
                    theme="secondary"
            >{{ 'lta.cancel' | translate }}
            </lta-btn>
        </div>
    </lta-modal>
</lta-modal-layout>
