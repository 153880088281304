import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HeaderLayoutService {
    private isLoadingSubject = new BehaviorSubject<boolean>(false)
    public isLoading$ = this.isLoadingSubject.asObservable()
    private isHeaderLoadingSubject = new BehaviorSubject<boolean>(false)
    public isHeaderLoading$ = this.isHeaderLoadingSubject.asObservable()

    constructor() {
    }

    public setLoading(bool: boolean) {
        this.isLoadingSubject.next(bool)
    }

    public setHeaderLoading(bool: boolean) {
        this.isHeaderLoadingSubject.next(bool)
    }
}
