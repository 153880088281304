import {Inject, Injectable} from "@angular/core";
import {EventsHttpService, EventWebsocketMessage, IEvent, IEventClass, WebsocketService} from "@atl/lacerta-ui-common";
import {BehaviorSubject, Observable} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EventClassesService} from "app/@atl/administration/event-classes/services";
import {first, map, tap} from "rxjs/operators";
import {mergeObjects} from "@atl/shared/utils";

export const RAISED_EVENTS_WEBSOCKET = 'ACTIVE_EVENTS_WEBSOCKET'

@UntilDestroy()
@Injectable()
export class RaisedEventsService {
    public uncheckedEventsCount$ = new BehaviorSubject<string>(null)
    public lastEventColor$ = new BehaviorSubject<string>(null)
    public hasRaisedEvents$ = new BehaviorSubject<boolean>(false)
    public raisedEventsSubject = new BehaviorSubject<(IEvent & { event_class?: IEventClass })[]>([])
    public raisedEvents$ = this.raisedEventsSubject.asObservable()
    public uncheckedEvents$ = new BehaviorSubject<(IEvent & { event_class?: IEventClass })[]>([])
    public soundEventSubject = new BehaviorSubject<(IEvent & { event_class?: IEventClass })>(null)
    public soundEvent$ = this.soundEventSubject.asObservable().pipe(tap(ev => {
        const soundId = this.eventClassesService.getById(ev?.event_class_id)?.event_class_sound?.id
        if (soundId) {
            this.playSound(soundId)
        } else {
            this.stopSound()
        }
    }))
    private events$: Observable<EventWebsocketMessage>
    private unsubscribeFn: () => void
    private lastAudio: { interval: NodeJS.Timeout, audio: HTMLAudioElement, soundId: number } = null
    private audioInProgress = false

    constructor(@Inject(RAISED_EVENTS_WEBSOCKET) private websocketService: WebsocketService, private eventClassesService: EventClassesService, private eventsHttpService: EventsHttpService) {
    }

    static isEventChecked(event: IEvent): boolean {
        return !!event.tm_check
    }

    static isEventActive(event: IEvent): boolean {
        return !event.tm_deactivate || event.tm_deactivate === 0
    }

    static canAcknowledgeEvent(event: IEvent, eventClass: IEventClass): boolean {
        return eventClass?.can_check && !this.isEventChecked(event)
    }

    public destroy() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn()
        }
    }

    public subscribeToObjectEvents(objectId: number) {
        return this.raisedEvents$.pipe(map(events => events.filter(ev => objectId ? ev.object_path.some(p => p.id === objectId) : true)))
    }

    public init() {
        this.eventClassesService.getEventClasses().subscribe(() => {
            const [events$, unsubscribeFn] = this.websocketService.subscribeToEvents({});
            this.events$ = events$
            this.unsubscribeFn = unsubscribeFn
            this.events$.pipe(untilDestroyed(this)).subscribe(ev => this.mergeEvents(ev))

            this.getAllRaisedEvents().subscribe(res => {
                if (!res.events || !res.events.length) return
                const eventsWithClass = this.addClassToEvents(res.events)
                this.raisedEventsSubject.next(eventsWithClass)

            })
        })

        this.raisedEvents$.pipe(untilDestroyed(this)).subscribe(events => {
            this.uncheckedEvents$.next(events.filter(value => this.eventClassesService.getById(value.event_class_id)?.can_check).filter(e => !RaisedEventsService.isEventChecked(e)))
            const uncheckedEvents = this.uncheckedEvents$.value

            this.uncheckedEventsCount$.next(uncheckedEvents.length ? uncheckedEvents.length > 99 ? '99+' : uncheckedEvents.length.toString() : '')
            this.lastEventColor$.next(
                uncheckedEvents.length ?
                    this.eventClassesService.getById(uncheckedEvents[0].event_class_id)?.color_background :
                    this.eventClassesService.getById(events[0]?.event_class_id)?.color_background_checked
            )
            const eventsWithSound = uncheckedEvents.filter(e => !!this.eventClassesService.getById(e.event_class_id)?.event_class_sound)
            const sortByPriority = [...eventsWithSound].sort((a, b) => this.eventClassesService.getById(b.event_class_id).priority - this.eventClassesService.getById(a.event_class_id).priority)
            this.soundEventSubject.next(
                sortByPriority.length ?
                    sortByPriority[0] :
                    null
            )
            this.hasRaisedEvents$.next(events.length > 0)
        })

        this.eventClassesService.getEventClassSounds().subscribe()

        this.soundEvent$.pipe(untilDestroyed(this)).subscribe()
    }

    public manualUpdateRaisedEvents() {
        this.raisedEvents$.pipe(first()).subscribe(events => {
            this.raisedEventsSubject.next(events)
        })
    }

    private getAllRaisedEvents() {
        return this.eventsHttpService.getEvents({
            count: 99999,
            object_path: true,
        }, 'raised')
    }

    private addClassToEvents(events: IEvent[]) {
        return events.map(v => {
            return {...v, event_class: this.eventClassesService.getById(v.event_class_id)}
        })
    }

    private stopSound() {
        if (!this.lastAudio) return
        clearInterval(this.lastAudio.interval)
        this.lastAudio.audio.remove()
        this.lastAudio = null
    }

    private playSound(soundId: number) {
        if (this.audioInProgress) return;
        const sound = this.eventClassesService.getSoundById(soundId)
        if (this.lastAudio?.soundId === soundId) return

        this.stopSound()

        if (!sound) return

        const audio = new Audio();
        audio.src = sound;
        audio.load();

        this.audioInProgress = true
        audio.oncanplaythrough = () => {
            audio.play().catch(() => null)

            this.lastAudio = {
                interval: setInterval(() => {
                    audio.play().catch(() => null)
                }, audio.duration * 1000 + 1000),
                soundId,
                audio
            }
            audio.oncanplaythrough = null
            this.audioInProgress = false
        };


    }

    private mergeEvents(ev: EventWebsocketMessage) {
        let events = this.raisedEventsSubject.value
        ev.ev_msg.forEach(event => {
            let isUniqueEvent = true
            let indexToDelete = null
            const newEvent = event
            events = events.map((d, index) => {
                if (d.object_id === newEvent.object_id && d.id_event === newEvent.id_event && d.edge === newEvent.edge) {
                    isUniqueEvent = false
                    if (!newEvent.raised) indexToDelete = index
                    return mergeObjects(d, newEvent)
                }
                return d
            })

            if (indexToDelete) events.splice(indexToDelete, 1)

            if (isUniqueEvent) {
                const eventWithClass = this.addClassToEvents([newEvent])[0]
                events = [eventWithClass, ...events]
            }
        })

        this.raisedEventsSubject.next(events)
    }
}
