import {Inject, Injectable} from "@angular/core"
import {CanActivate, UrlTree} from "@angular/router"
import {Observable} from "rxjs"
import {UrlService} from "@atl/shared/services/url.service";
import {AuthService, AUTH_SERVICE} from "@atl/lacerta-ui-common";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private urlService: UrlService,
        @Inject(AUTH_SERVICE) private authService: AuthService,
    ) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isLoggedIn()) {
            this.urlService.goToAdmin()
        }
        return true
    }
}
