import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {ModalLayoutModule} from "@atl/modules/modals/modal-layout/modal-layout.module";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {BaseModalModule} from "@atl/modules/modals/base-modal/base-modal.module";
import {LtaTreeModule} from "@atl/modules/tree/lta-tree.module";
import {PreloaderModule} from "@atl/modules/preloader/preloader.module";
import {SearchModule} from "@atl/modules/form-controls/search/search.module";
import {TranslateModule} from "@ngx-translate/core";
import {
    LtaBtnModule,
    LtaCheckboxRuntimeModule,
    LtaInputModule,
    LtaObjectHierarchyModule,
    LtaSvgIconModule
} from "@atl/lacerta-ui-common";

@UntilDestroy()
@Component({
    selector: 'lta-runtime-modal',
    templateUrl: 'runtime-modal.component.html',
    styleUrls: ['runtime-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ModalLayoutModule,
        AsyncPipe,
        BaseModalModule,
        LtaBtnModule,
        LtaCheckboxRuntimeModule,
        LtaInputModule,
        LtaTreeModule,
        NgForOf,
        NgIf,
        LtaObjectHierarchyModule,
        PreloaderModule,
        SearchModule,
        LtaSvgIconModule,
        TranslateModule
    ],
    standalone: true
})
export class RuntimeModalComponent implements OnInit {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Input() height: string;
    @Input() width: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
