import {Component, Input} from '@angular/core';

@Component({
  selector: 'lta-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
    @Input() background: 'shadow' | 'transparent' = 'transparent';
    @Input() top: number = 60;
}
