<div
        [class.focused]="isFocused(inputTarget)"
        [ngClass]="theme"
        class="search"
>
    <lta-svg-icon
            (click)="_onSearch()"
            class="icon"
            icon="search"
            size="20"
    ></lta-svg-icon>
    <input
            #inputTarget
            (change)="_onChange()"
            (keyup.enter)="_onSearch()"
            [(ngModel)]="value"
            [disabled]="disabled"
            autocomplete="false"
            placeholder="{{ placeholder || 'search' | translate }}"
            type="text"
    >
    <ng-container *ngIf="searchResult">
        <span *ngIf="currentSearchResultIndex">{{currentSearchResultIndex}}/{{searchResult}}</span>
        <span class="separator"></span>
        <span
                (click)="onNext()"
                class="icon-shadow"
        >
        <lta-svg-icon
                class="icon"
                icon="chevron-down"
                size="20"
        ></lta-svg-icon>
        </span>
        <span
                (click)="onBack()"
                class="icon-shadow"
        >
        <lta-svg-icon
                class="icon"
                icon="chevron-up"
                size="20"
        ></lta-svg-icon>
        </span>
    </ng-container>
    <span
            *ngIf="value"
            class="icon-shadow"
    >
    <lta-svg-icon
            (click)="_onClear()"
            class="close"
            icon="close"
            size="20"
    ></lta-svg-icon>
    </span>
</div>
