import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {Subject, Subscription} from "rxjs";

@Directive({
    selector: '[lta-flash-message]',
})
export class FlashMessageDirective implements OnInit, OnChanges, OnDestroy {
    @Input('lta-flash-message') message = ''
    @Input() delay? = 1000
    @Input() searchError: Error = null
    @Input() position?: 'top' | 'down' = 'down'

    private myPopup
    private timer
    private messageSubject = new Subject()
    private messageSubscription: Subscription

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.searchError) return
        this.messageSubject.next(true)
    }

    ngOnInit(): void {
        this.messageSubscription = this.messageSubject
            .subscribe(() => {
                this.handleMessage()
            })
    }

    ngOnDestroy(): void {
        if (this.myPopup) {
            this.myPopup.remove()
        }
        this.messageSubscription?.unsubscribe()
    }

    private handleMessage() {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        if (this.myPopup) {
            this.myPopup.remove()
        }
        const elementPosition = this.el.nativeElement.getBoundingClientRect()
        const elementSize = this.el.nativeElement
        let x, y

        if (this.position === 'top') {
            x = elementPosition.left - elementSize.offsetWidth - 100
            y = elementPosition.top - 35
        } else {
            x = elementPosition.left + elementSize.offsetWidth / 2
            y = elementPosition.bottom + 10
        }
        this.createPopup(x, y)
    }

    private createPopup(x: number, y: number) {
        let popup = document.createElement('div')
        popup.innerHTML = this.message

        this.renderer.addClass(popup, 'tooltip-container');
        this.renderer.setStyle(popup, 'width', '225px');
        this.renderer.setStyle(popup, 'top', y + 'px');
        this.renderer.setStyle(popup, 'left', x + 'px');
        this.renderer.setStyle(popup, 'z-index', 100000);
        this.renderer.appendChild(document.body, popup);
        if (this.position === 'down') {
            this.renderer.setStyle(popup, 'left', (x - popup.clientWidth / 2) + 'px');
        }
        this.myPopup = popup
        this.timer = setTimeout(() => {
            if (this.myPopup) {
                this.myPopup.remove()
            }
        }, 3000)
    }
}
