<button 
        (click)="back()" 
        [lta-tooltip]="'common.back' | translate"
        [position]="'down'" 
        [disabled]="canGoBack$ | async" class="icon-wrapper">
    <lta-svg-icon [asCanvas]="true" height="16" icon="arrow-long-left" width="32"></lta-svg-icon>
</button>

<button 
        (click)="forward()" 
        [lta-tooltip]="'common.forward' | translate"
        [position]="'down'" 
        [disabled]="canGoForward$ | async" class="icon-wrapper">
    <lta-svg-icon [asCanvas]="true" height="16" icon="arrow-long-right" width="32"></lta-svg-icon>
</button>
